import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Header, Icon, Popup, Table } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useUpdateQualifaiConversationMutation } from 'src/api/qualifai-conversations';
import { useListQualifaiScorecardsQuery } from 'src/api/qualifai-scorecards';
import ApiMessage from 'src/components/ApiMessage';
import PublicFileUploader from 'src/components/ImageUploader';
import { Note, Row } from 'src/styles';
import {
  ConversationIntents,
  QualifaiConversation,
  QualifaiConversationNode,
  QualifaiConversationNodeActions,
  SpeechToTextProviders,
} from 'src/types';
import { Layout } from './style';

type ValidationErrors = {
  // General
  name?: string;
  // Inbound
  // greetingAudioUrl?: string;
  // Outbound
  // segueAudioUrl?: string;
};

const getInitialFormdata = (conversation: QualifaiConversation): QualifaiConversation => {
  const next = _cloneDeep(conversation);

  // Set any missing defaults and/or apply data type conversions
  // if (next.schedule && !next.schedule.timezone) {
  //   next.schedule.timezone = 'America/New_York';
  // }

  return next;
};

type EditFormProps = {
  conversation: QualifaiConversation;
};

const EditQualifaiConversationForm = ({ conversation }: EditFormProps) => {
  const { mutateAsync, isLoading: saveLoading } = useUpdateQualifaiConversationMutation();
  const { data: scorecards, isLoading: scorecardsLoading } = useListQualifaiScorecardsQuery();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [isValid, setIsValid] = useState(false);
  const [formdata, setFormdata] = useState<QualifaiConversation>(() => getInitialFormdata(conversation));
  const [saved, setSaved] = useState(true);
  const [viewErrors, setViewErrors] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const [selectedNodeId, setSelectedNodeId] = useState<string>(
    Object.values(conversation.nodes).find(n => n.order === 0)?.id || ''
  );

  const selectedNode: QualifaiConversationNode | undefined = formdata.nodes[selectedNodeId];

  // TODO: This does not work when using back/forward browser buttons
  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [saved]);

  const validate = useCallback(
    (c: QualifaiConversation) => {
      let errors = {} as ValidationErrors;

      // General
      if (!c.name.trim()) {
        errors.name = 'Name is required';
      }

      const isValid = isEmpty(errors);

      if (!viewErrors) {
        errors = {} as ValidationErrors;
      }

      setErrors(errors);
      setIsValid(isValid);

      return isValid;
    },
    [setIsValid, viewErrors]
  );

  useEffect(() => {
    // Run once just to set the initial state
    validate(conversation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveConversation = useCallback(
    async (c: QualifaiConversation, force = false) => {
      if (saved && !force) {
        return;
      }

      setApiMessage(undefined);

      try {
        await mutateAsync(c);
        setSaved(true);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync, saved]
  );

  const onChange = useCallback(
    (_, { checked, name, value }) => {
      setFormdata(prev => {
        let v = typeof checked !== 'undefined' ? checked : value;
        if (name.includes('audioDuration')) {
          v = Number(v);
          if (Number.isNaN(v)) {
            v = 0;
          }
        }

        const next = _cloneDeep(prev);
        _set(next, name, v);

        validate(next);

        return next;
      });
      setSaved(false);
    },
    [validate]
  );

  const addSharedOutput = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      next.sharedOutputs.push({
        contextName: '',
        nodeId: '',
      });
      return next;
    });
    setSaved(false);
  }, []);

  const removeSharedOutput = useCallback(
    (index: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);
        next.sharedOutputs.splice(index, 1);
        return next;
      });
      setSaved(false);
    },
    []
  );

  const addNode = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      const newNode = {
        id: uuid(),
        order: Object.values(prev.nodes).reduce((acc, n) => (n.order > acc ? n.order : acc), 0) + 1,
        name: `Node ${Object.keys(prev.nodes).length + 1}`,
        nextNodeId: '',
        allowInterruptions: false,
        audioUrl: '',
        audioTranscript: '',
        audioDuration: 0,
        action: '',
        outputs: [],
        data: {},
      } as QualifaiConversationNode;

      next.nodes[newNode.id] = newNode;

      setSelectedNodeId(newNode.id);

      return next;
    });
    setSaved(false);
  }, []);

  const removeNode = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      delete next.nodes[selectedNodeId];
      return next;
    });
    setSaved(false);
    setSelectedNodeId('');
  }, [selectedNodeId]);

  const addNodeOutput = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      let outputs = next.nodes[selectedNodeId].outputs;
      if (!outputs) {
        outputs = [];
      }
      outputs.push({
        contextName: '',
        nodeId: '',
      });
      next.nodes[selectedNodeId].outputs = outputs;
      return next;
    });
    setSaved(false);
  }, [selectedNodeId]);

  const removeNodeOutput = useCallback(
    (index: number) => () => {
      setFormdata(prev => {
        const next = _cloneDeep(prev);
        let outputs = next.nodes[selectedNodeId].outputs;
        if (!outputs) {
          outputs = [];
          next.nodes[selectedNodeId].outputs = outputs;
          return next;
        }
        outputs.splice(index, 1);
        next.nodes[selectedNodeId].outputs = outputs;
        return next;
      });
      setSaved(false);
    },
    [selectedNodeId]
  );

  const toggleViewErrors = () => setViewErrors(prev => !prev);

  return (
    <Form style={{ position: 'relative' }} onSubmit={() => saveConversation(formdata, true)}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Popup
            trigger={
              <Button
                size="mini"
                compact
                color="red"
                style={{ marginLeft: '0.5rem' }}
                type="button"
                onClick={toggleViewErrors}
              >
                <Icon name="dont" />
                Invalid
                <Icon name={viewErrors ? 'eye' : 'eye slash'} style={{ marginLeft: '0.5rem', marginRight: 0 }} />
              </Button>
            }
          >
            {viewErrors ? 'Hide' : 'Show'} validation errors
          </Popup>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <Layout>
        <div style={{ gridArea: 'general' }}>
          <Header>
            General
            {/* {formdata.tmpConfig.recordingEnabled && (
              <Label color="red" title="Call Recording Enabled">
                <Icon name="circle" />
                REC
              </Label>
            )} */}
          </Header>

          <Form.Checkbox toggle label="Enabled" name="enabled" checked={formdata.enabled} onChange={onChange} />

          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />

          <Form.Select
            label="Start Node"
            name="startNode"
            value={formdata.startNode}
            onChange={onChange}
            options={Object.values(formdata.nodes)
              .filter(n => n.order >= 0)
              .map(n => ({ key: n.id, value: n.id, text: n.name }))}
          />

          <Form.Select
            clearable
            placeholder="Dialogflow"
            label="Speech to Text Provider"
            name="sttProvider"
            value={formdata.sttProvider}
            onChange={onChange}
            options={SpeechToTextProviders.map(p => ({ ...p, key: p.value }))}
          />

          {/* <Form.Checkbox
            toggle
            label="Enabled Call Recording?"
            name="tmpConfig.recordingEnabled"
            checked={formdata.tmpConfig.recordingEnabled}
            onChange={onChange}
          />
          <Note>
            If enabled, the call recording will begin AFTER a human is detected (for outbound calls) and BEFORE the
            greeting audio is played. If call recordings are enabled on the voice config, this settings will be ignored.
          </Note> */}

          <Header>Shared Output(s)</Header>
          <Note>
            When any of the following intents are detected on any question, the call will proceed to the specified node.
          </Note>

          {formdata.sharedOutputs.length > 0 && (
            <Table celled>
              <Table.Body>
                {formdata.sharedOutputs.map((output, index) => (
                  <Table.Row key={`sharedOutput-${index}`}>
                    <Table.Cell>
                      <Form.Select
                        label="Intent Name"
                        name={`sharedOutputs.${index}.contextName`}
                        onChange={onChange}
                        value={output.contextName}
                        options={ConversationIntents.map(i => ({
                          key: i.value,
                          value: i.value,
                          text: i.text,
                        }))}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Select
                        label="Node"
                        name={`sharedOutputs.${index}.nodeId`}
                        onChange={onChange}
                        value={output.nodeId || ''}
                        options={Object.values(formdata.nodes).map(n => ({ key: n.id, value: n.id, text: n.name }))}
                      />
                    </Table.Cell>
                    <Table.Cell collapsing>
                      <Button type="button" icon color="red" onClick={removeSharedOutput(index)}>
                        <Icon name="trash" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}

          <Button type="button" size="mini" compact color="blue" onClick={addSharedOutput}>
            <Icon name="plus" />
            Add Output
          </Button>
        </div>

        <div style={{ gridArea: 'nodes' }}>
          <Header>Nodes</Header>
          {/* <Note>
            At the beginning of the call, as soon as a human is detected, this greeting will be played to the prospect.
          </Note> */}

          <Row style={{ marginBottom: '1rem' }}>
            {Object.values(formdata.nodes)
              // .filter(n => n.order >= 0)
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map(n => (
                <Button
                  key={n.id}
                  type="button"
                  size="tiny"
                  compact
                  color={selectedNode?.name === n.name ? 'blue' : undefined}
                  basic={n.order < 0}
                  onClick={() => setSelectedNodeId(n.id)}
                >
                  {n.name}
                </Button>
              ))}

            <Button type="button" size="tiny" compact icon onClick={addNode}>
              <Icon name="plus" />
            </Button>
          </Row>

          <Form.Group>
            <Form.Input
              width={12}
              label="Name"
              name={`nodes.${selectedNodeId}.name`}
              onChange={onChange}
              value={selectedNode?.name || ''}
            />

            <Form.Field width={4}>
              <label>Allow Interruptions</label>
              <Form.Checkbox
                toggle
                name={`nodes.${selectedNodeId}.allowInterruptions`}
                checked={selectedNode?.allowInterruptions}
                onChange={onChange}
              />
            </Form.Field>
          </Form.Group>

          {selectedNode?.order >= 0 && (
            <>
              <Form.Group>
                <Form.TextArea
                  width={12}
                  label="Audio Transcript"
                  // error={errors.greetingAudioUrl}
                  name={`nodes.${selectedNodeId}.audioTranscript`}
                  onChange={onChange}
                  value={selectedNode?.audioTranscript || ''}
                />

                <Form.Input
                  width={4}
                  label="Audio Duration (ms)"
                  name={`nodes.${selectedNodeId}.audioDuration`}
                  onChange={onChange}
                  value={selectedNode?.audioDuration || ''}
                />
              </Form.Group>

              <Form.Input
                label="Audio URL"
                // error={errors.greetingAudioUrl}
                name={`nodes.${selectedNodeId}.audioUrl`}
                onChange={onChange}
                value={selectedNode?.audioUrl || ''}
              />
              <PublicFileUploader
                fileType="audio"
                name={`nodes.${selectedNodeId}.audioUrl`}
                onChange={onChange}
                value={selectedNode?.audioUrl || ''}
              />
            </>
          )}

          <Form.Select
            clearable
            label="Action"
            placeholder="None"
            name={`nodes.${selectedNodeId}.action`}
            onChange={onChange}
            options={QualifaiConversationNodeActions.map(a => ({ ...a, key: a.value }))}
            value={selectedNode?.action || ''}
          />

          {selectedNode?.action === 'continue' && (
            <Form.Select
              clearable
              label="Next Node"
              name={`nodes.${selectedNodeId}.nextNodeId`}
              onChange={onChange}
              value={selectedNode?.nextNodeId || ''}
              options={Object.values(formdata.nodes)
                .filter(n => n.order >= 0 && n.id !== selectedNodeId)
                .map(n => ({ key: n.id, value: n.id, text: n.name }))}
            />
          )}

          {selectedNode?.action === 'transfer' && (
            <>
              <Form.Select
                clearable
                loading={scorecardsLoading}
                label="Pre-Transfer Evaluation Scorecard"
                name={`nodes.${selectedNodeId}.data.pretransfer_scorecard_id`}
                onChange={onChange}
                value={selectedNode?.data?.pretransfer_scorecard_id || ''}
                options={scorecards?.map(s => ({ key: s.id, value: s.id, text: s.title })) || []}
              />
            </>
          )}

          {selectedNode?.order >= 0 && (
            <>
              <Form.Field>
                <label>Output(s)</label>

                {(selectedNode?.outputs || []).length > 0 && (
                  <Table celled>
                    <Table.Body>
                      {(selectedNode?.outputs || []).map((output, index) => (
                        <Table.Row key={`node-output-${index}`}>
                          <Table.Cell>
                            <Form.Select
                              label="Intent Name"
                              name={`nodes.${selectedNodeId}.outputs.${index}.contextName`}
                              onChange={onChange}
                              value={output.contextName || ''}
                              options={ConversationIntents.map(i => ({
                                ...i,
                                key: i.value,
                              }))}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Select
                              label="Node"
                              name={`nodes.${selectedNodeId}.outputs.${index}.nodeId`}
                              onChange={onChange}
                              value={output.nodeId || ''}
                              options={Object.values(formdata.nodes).map(n => ({
                                key: n.id,
                                value: n.id,
                                text: n.name,
                              }))}
                            />
                          </Table.Cell>
                          <Table.Cell collapsing>
                            <Button type="button" icon color="red" onClick={removeNodeOutput(index)}>
                              <Icon name="trash" />
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}

                <Button type="button" size="mini" compact color="blue" onClick={addNodeOutput}>
                  <Icon name="plus" />
                  Add Output
                </Button>
              </Form.Field>

              <Button type="button" color="red" basic onClick={removeNode}>
                <Icon name="trash" />
                Delete Node
              </Button>
            </>
          )}
        </div>
      </Layout>
    </Form>
  );
};

export default EditQualifaiConversationForm;
