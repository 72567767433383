import { snakeCase } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _set from 'lodash/set';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Divider,
  DropdownItemProps,
  DropdownProps,
  Form,
  Header,
  Icon,
  InputOnChangeData,
  Segment,
  Select,
  SelectProps,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useListBlacklistsQuery } from 'src/api/blacklists';
import { useListDatasetsQuery, useSaveDatasetMutation } from 'src/api/datasets';
import { useListFeedsQuery } from 'src/api/feeds';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListLeadscoreConfigsQuery } from 'src/api/leadscore-plus';
import { useListWebhookConfigsQuery } from 'src/api/webhooks';
import ApiMessage from 'src/components/ApiMessage';
import { Note, StyledFieldset } from 'src/styles';
import { BaseSchema, Dataset, RuleFilter, TimeRanges } from 'src/types';
import CarrierNames from '../../datasets/edit/carriers.json';
import DatasetV2Filters from './DatasetV2Filters';
import DataseV2RemoveFieldModal from './DataseV2RemoveFieldModal';
import LeadscoreConfigCosts from './LeadscoreConfigCosts';

const GridLayout = styled.div`
  display: grid;
  grid-template-areas: 'general v1 rules v2 triggers';
  grid-template-columns: 1fr auto 1fr auto 1fr;
  grid-template-rows: auto;
  gap: 1rem;
`;

const Layout: FC = ({ children }) => {
  return (
    <GridLayout>
      {children}

      <Divider vertical style={{ gridArea: 'v1', position: 'relative' }}>
        <Icon name="arrow right" color="grey" />
      </Divider>

      <Divider vertical style={{ gridArea: 'v2', position: 'relative' }}>
        <Icon name="arrow right" color="grey" />
      </Divider>
    </GridLayout>
  );
};

const DedupByOptions: DropdownItemProps[] = [
  { key: 'phone', text: 'Phone', value: 'phone' },
  { key: 'email', text: 'Email', value: 'email' },
  // { key: 'first_last_zip', text: 'firstname, lastname, zip', value: 'first_last_zip' },
  // { key: 'full_record', text: 'Full Record', value: 'full_record' },
];

const DedupWithinOptions: DropdownItemProps[] = TimeRanges.map(r => ({ key: r.value, value: r.value, text: r.text }));

const ExcludeCarrierOptions: DropdownItemProps[] = CarrierNames.map(name => ({ key: name, value: name, text: name }));

const SelectCarriers = ({ value, ...rest }: Omit<SelectProps, 'options'>) => {
  const [search, setSearch] = useState('');

  const options = useMemo(() => {
    const selectedOptions = ExcludeCarrierOptions.filter(o => (value || []).indexOf(o.value) > -1);

    if (search) {
      return [
        ...selectedOptions,
        ...ExcludeCarrierOptions.filter(o => String(o.value).toLowerCase().includes(search.toLowerCase())),
      ];
    }

    return [...selectedOptions, ...ExcludeCarrierOptions.slice(0, 10)];
  }, [search, value]);

  return (
    <Form.Select
      options={options}
      multiple
      search
      searchQuery={search}
      onSearchChange={(_, { searchQuery }) => setSearch(searchQuery)}
      clearable
      value={value}
      {...rest}
    />
  );
};

type ValidationErrors = {
  rulesBlacklists?: string;
};

type Props = {
  dataset: Dataset;
};

const DatasetV2Settings = ({ dataset }: Props) => {
  const [saved, setSaved] = useState(true);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { data: user } = useGetUserProfileQuery();
  const { mutateAsync, isLoading: saveLoading } = useSaveDatasetMutation();
  const { data: feeds, isLoading: feedsLoading } = useListFeedsQuery({ limit: 100, offset: 0 });
  const { data: webhooks, isLoading: webhooksLoading } = useListWebhookConfigsQuery({ limit: 100, offset: 0 });
  const { data: leadscoreConfigs, isLoading: leadscoreConfigsLoading } = useListLeadscoreConfigsQuery({
    limit: 100,
    offset: 0,
  });
  const { data: allDatasets, isLoading: tablesLoading } = useListDatasetsQuery({ limit: 100, offset: 0 });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<Dataset>({
    id: '',
    created_at: '',
    account_id: 0,
    name: '',
    // section 1
    custom_fields: [],
    field_mappings: [],
    // section 2
    rules: {
      blacklists: [],
      deduplicate: {
        enabled: false,
        allow_ext_dataset_trigger: false,
        on: 'phone',
        within: 604800,
        datasets: [],
      },
      exclude_carriers: [],
      exclude_connects: false,
      exclude_landlines: false,
      filters: [],
      required_fields: [],
    },
    // section 3
    leadscore_config_id: '',
    synthetic_data: {
      zipcode_enabled: false,
      city_state_enabled: false,
      firstname_lastname_enabled: false,
    },
    feed_id: 0,
    webhook_config_id: '',
  });

  const selectedLeadscoreConfig = useMemo(() => {
    return (leadscoreConfigs?.data || []).find(c => c.id === formdata.leadscore_config_id);
  }, [formdata.leadscore_config_id, leadscoreConfigs?.data]);

  useEffect(() => {
    if (!dataset) return;
    setFormdata(dataset);
  }, [dataset]);

  const dedupTableOptions =
    allDatasets?.data
      ?.filter(ds => ds.id !== dataset.id)
      .map(ds => {
        return {
          key: ds.id,
          text: ds.name,
          value: ds.id,
        };
      }) || [];

  const addCustomField = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(next, 'custom_fields', [...(next.custom_fields || []), { id: uuid(), type: 'STRING' }]);
      return next;
    });
    setSaved(false);
  }, []);

  const updateCustomField = useCallback(
    (id: string) =>
      (_: any, { name, value }: InputOnChangeData | DropdownProps) => {
        setFormdata(prev => {
          const next = _cloneDeep(prev);

          _set(
            next,
            `custom_fields`,
            (next.custom_fields || []).map(f => (f.id === id ? { ...f, [name]: value } : f))
          );

          if (name === 'friendly_name') {
            const cleanValue = snakeCase(value as string);
            _set(
              next,
              `custom_fields`,
              (next.custom_fields || []).map(f => (f.id === id ? { ...f, name: cleanValue } : f))
            );
          }

          return next;
        });
        setSaved(false);
      },
    []
  );

  const addFilter = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(next, 'rules.filters', [...(next.rules.filters || []), { id: uuid() }]);
      return next;
    });
    setSaved(false);
  }, []);

  const removeFilter = useCallback((id: string) => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(
        next,
        'rules.filters',
        (next.rules.filters || []).filter(f => f.id !== id)
      );
      return next;
    });
    setSaved(false);
  }, []);

  const updateFilter = useCallback((id: string, filter: RuleFilter) => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(
        next,
        'rules.filters',
        (next.rules.filters || []).map(f => (f.id === id ? filter : f))
      );
      return next;
    });
    setSaved(false);
  }, []);

  const { data: blacklists } = useListBlacklistsQuery({ limit: 100, offset: 0 });
  const blacklistOptions = useMemo(() => {
    return (blacklists?.data || []).map(b => ({
      key: b.id,
      value: b.id,
      text: b.name,
    }));
  }, [blacklists]);

  const requiredColumnsOptions = useMemo(
    () => [
      ...BaseSchema.map(f => ({ key: f.id, value: f.name, text: f.name })),
      ...(dataset?.custom_fields || []).map(f => ({
        key: f.id,
        value: f.name,
        text: f.name,
      })),
    ],
    [dataset]
  );

  const saveSettings = useCallback(
    async (formdata: Dataset) => {
      try {
        await mutateAsync({ dataset: formdata });
        setSaved(true);
        setApiMessage(undefined);
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync]
  );

  const debouncedSave = useMemo(() => _debounce(saveSettings, 3000), [saveSettings]);

  const canAddBlacklist = (listIDs: number[]) => {
    return listIDs.includes(1) && listIDs.includes(2) ? false : true;
  };

  const onChange = useCallback((_, { checked, name, value }) => {
    setFormdata(prev => {
      let v = typeof checked !== 'undefined' ? checked : value;
      if (name === 'feed_id') {
        v = Number(v);
      }

      const next = _cloneDeep(prev);
      _set(next, name, v);
      return next;
    });

    setSaved(false);
  }, []);

  const FieldMappingOptions = useMemo(() => {
    // Return list of base fields + custom fields
    const items = BaseSchema.map(item => {
      return {
        key: item.id,
        text: item.name,
        value: item.name,
      };
    });

    const customItems =
      formdata.custom_fields?.map(customField => {
        return {
          key: customField.id,
          text: customField.name,
          value: customField.name,
        };
      }) || [];

    return items.concat(customItems);
  }, [formdata.custom_fields]);

  useEffect(() => {
    debouncedSave(formdata);
  }, [debouncedSave, formdata]);

  return (
    <>
      <ApiMessage data={apiMessage} />

      <Segment>
        <Form>
          <Button
            size="mini"
            compact
            color={saveLoading ? 'blue' : saved ? 'green' : 'red'}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 100, margin: 0 }}
          >
            {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
            {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
          </Button>

          <Layout>
            <div style={{ gridArea: 'general' }}>
              <Header>General</Header>

              <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} />

              <Header>Custom Fields</Header>
              {(formdata.custom_fields || []).map(f => {
                const errors: { [key: string]: string } = {};
                if (!f.name) {
                  errors.name = 'Name is required';
                }
                if (!!BaseSchema.find(baseField => f.name === baseField.name)) {
                  errors.name = 'Name already in use';
                }
                if (
                  !!(formdata.custom_fields || []).find(
                    customField => f.name === customField.name && f.id !== customField.id
                  )
                ) {
                  errors.name = 'Name already in use';
                }

                return (
                  <Form.Group key={f.id} widths="equal">
                    <Form.Select
                      fluid
                      label="Type"
                      placeholder="Type"
                      name="type"
                      options={[
                        { key: 'STRING', value: 'STRING', text: 'String' },
                        { key: 'NUMBER', value: 'NUMBER', text: 'Number' },
                        { key: 'BOOL', value: 'BOOL', text: 'Boolean' },
                        { key: 'TIMESTAMP', value: 'TIMESTAMP', text: 'Timestamp' },
                      ]}
                      onChange={updateCustomField(f.id)}
                      error={!f.type && 'Type is required'}
                      value={f.type}
                    />

                    <Form.Input
                      fluid
                      value={f.friendly_name}
                      name="friendly_name"
                      label="Label"
                      onChange={updateCustomField(f.id)}
                    />

                    <Form.Input
                      fluid
                      value={f.name}
                      name="name"
                      label="Internal Name"
                      onChange={updateCustomField(f.id)}
                      onBlur={() => updateCustomField(f.id)(null, { name: 'name', value: snakeCase(f.name) })}
                      error={errors.name}
                    />

                    <DataseV2RemoveFieldModal dataset={dataset} fieldId={f.id} />
                  </Form.Group>
                );
              })}

              <Button
                type="button"
                compact
                size="mini"
                color="blue"
                style={{ marginBottom: '1rem' }}
                onClick={addCustomField}
              >
                <Icon name="plus" /> Add Custom Field
              </Button>

              <Header>Field Mappings</Header>
              <Note>
                If you are unable to customize the field names sent by your API or CRM when posting the data into this
                dataset, you can use these settings to map the data to the corresponding field in the dataset. Create a
                mapping for each field that does not match the dataset's schema.
              </Note>

              {formdata.field_mappings?.map((fieldMapping, i) => (
                <Form.Group key={i} widths="equal">
                  <Form.Input
                    fluid
                    placeholder="From field"
                    onChange={onChange}
                    name={`field_mappings[${i}].from`}
                    value={fieldMapping.from}
                    label="From"
                  />

                  <Form.Select
                    fluid
                    placeholder={fieldMapping.from || 'parameter name'}
                    value={fieldMapping.to}
                    name={`field_mappings[${i}].to`}
                    onChange={(_, { value }) => {
                      // Set field value
                      onChange(null, {
                        name: `field_mappings[${i}].to`,
                        value,
                      });

                      // Find field type
                      let field = BaseSchema.find(item => item.name === value);
                      if (!field) {
                        field = formdata.custom_fields?.find(customField => customField.name === value);
                      }

                      // Set field type
                      onChange(null, {
                        name: `field_mappings[${i}].type`,
                        value: field?.type,
                      });
                    }}
                    label="To"
                    options={FieldMappingOptions}
                  />

                  <Button
                    type="button"
                    color="red"
                    icon
                    style={{ marginTop: '1.5rem' }}
                    onClick={() => {
                      onChange(null, {
                        name: 'field_mappings',
                        value: formdata.field_mappings?.filter((_, j) => i !== j),
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Group>
              ))}

              <Button
                type="button"
                compact
                size="mini"
                color="blue"
                onClick={() => {
                  onChange(null, {
                    name: 'field_mappings',
                    value: [...(formdata.field_mappings || []), { from: '', to: '', type: 'STRING' }],
                  });
                }}
              >
                <Icon name="plus" />
                Add Field Mapping
              </Button>
            </div>

            <div style={{ gridArea: 'rules' }}>
              <Header>Rules</Header>

              <Form.Select
                multiple
                options={requiredColumnsOptions}
                label="Required Columns"
                value={formdata.rules.required_fields || []}
                name="rules.required_fields"
                onChange={onChange}
              />

              <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Checkbox
                  label="Deduplicate"
                  checked={formdata.rules.deduplicate?.enabled}
                  name="rules.deduplicate.enabled"
                  onChange={onChange}
                />

                <Form.Field style={{ width: 100 }}>
                  <Select
                    fluid
                    options={DedupByOptions}
                    value={formdata.rules.deduplicate?.on}
                    name="rules.deduplicate.on"
                    onChange={onChange}
                  />
                </Form.Field>

                <span>within</span>

                <Form.Field style={{ width: 120 }}>
                  <Select
                    fluid
                    options={DedupWithinOptions}
                    value={formdata.rules.deduplicate?.within}
                    name="rules.deduplicate.within"
                    onChange={onChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Select
                label="Deduplicate Across Other Datasets?"
                multiple
                options={dedupTableOptions}
                onChange={onChange}
                name="rules.deduplicate.datasets"
                loading={tablesLoading}
                disabled={!formdata.rules.deduplicate?.enabled}
                value={formdata.rules.deduplicate?.datasets || []}
              />

              {user?.role === 'admin' && (
                <StyledFieldset>
                  <legend>Admin Only</legend>
                  <Form.Checkbox
                    label="Allow Realtime Trigger for Cross-Dataset Duplicates"
                    name="rules.deduplicate.allow_ext_dataset_trigger"
                    checked={formdata.rules.deduplicate?.allow_ext_dataset_trigger || false}
                    onChange={onChange}
                  />
                </StyledFieldset>
              )}

              <Header as="h4">Filters</Header>
              <Note>
                To filter data in a specific column you must first make it required. Ingested data will be rejected if
                ANY filter does not pass.
              </Note>

              <DatasetV2Filters
                columns={requiredColumnsOptions.filter(c =>
                  formdata.rules.required_fields?.includes(c.value as string)
                )}
                filters={formdata.rules.filters || []}
                addFilter={addFilter}
                removeFilter={removeFilter}
                updateFilter={updateFilter}
              />

              <Form.Select
                label="Check Blacklist"
                options={blacklistOptions}
                multiple
                clearable
                search
                value={formdata.rules.blacklists || []}
                name="rules.blacklists"
                onChange={(e, d) => {
                  if (!canAddBlacklist(d.value as number[])) {
                    setErrors(prev => ({
                      ...prev,
                      rulesBlacklists: 'You are not allowed to select both Blacklist Alliance lists at the same time.',
                    }));
                    return;
                  }
                  onChange(e, d);
                  setErrors(prev => ({ ...prev, rulesBlacklists: undefined }));
                }}
                error={errors.rulesBlacklists}
              />

              <SelectCarriers
                label="Exclude Carriers"
                name="rules.exclude_carriers"
                onChange={onChange}
                value={formdata.rules.exclude_carriers || []}
              />

              <Form.Checkbox
                label="Exclude Connects"
                checked={formdata.rules.exclude_connects}
                name="rules.exclude_connects"
                onChange={onChange}
              />

              <Form.Checkbox
                label="Exclude Landlines"
                checked={formdata.rules.exclude_landlines}
                name="rules.exclude_landlines"
                onChange={onChange}
              />
            </div>

            <div style={{ gridArea: 'triggers' }}>
              <Header>LeadScore+</Header>
              <Note>
                With a LeadScore+ config selected, we will enrich and validate each record according to the settings in
                the config. If the data does not pass the config's requirements, it will be rejected but still stored
                into the dataset.
              </Note>

              <Form.Select
                label="LeadScore Config"
                name="leadscore_config_id"
                loading={leadscoreConfigsLoading}
                value={formdata.leadscore_config_id || ''}
                onChange={onChange}
                clearable
                options={(leadscoreConfigs?.data || []).map(c => ({
                  key: c.id,
                  value: c.id,
                  text: c.name,
                  description: !c.enabled ? 'Disabled' : '',
                }))}
              />

              {selectedLeadscoreConfig && <LeadscoreConfigCosts config={selectedLeadscoreConfig} />}

              <Divider />

              <Header>Synthetic Data</Header>
              <Note>
                With Synthetic Data enabled, we will attempt to lookup and append various data points to fill out each
                customer record. Synthetic Data will only be added into empty fields and will not overwrite any existing
                data found in the record.
              </Note>

              <Form.Checkbox
                toggle
                label="Enable Firstname + Lastname"
                checked={formdata.synthetic_data?.firstname_lastname_enabled}
                name="synthetic_data.firstname_lastname_enabled"
                onChange={onChange}
              />
              <Note>Enabling this feature will append a random firstname and lastname to the record.</Note>

              <Form.Checkbox
                toggle
                label="Enable City + State"
                checked={formdata.synthetic_data?.city_state_enabled}
                name="synthetic_data.city_state_enabled"
                onChange={onChange}
              />
              <Note>
                Enabling this feature will attempt to append the city and state to the record based on the zip (if
                available) or the area code of the phone number.
              </Note>

              <Form.Checkbox
                toggle
                label="Enable Zip Code"
                checked={formdata.synthetic_data?.zipcode_enabled}
                name="synthetic_data.zipcode_enabled"
                onChange={onChange}
              />
              <Note>
                Enabling this feature will attempt to lookup and append a zip code to the record based on the city and
                state (if available) or the area code of the phone number.
              </Note>

              <Divider />

              <Header>Triggers</Header>
              <Note>
                Only the incoming records received by the single record API endpoint will be passed through to the feed
                and/or webhook in realtime. Bulk data received via the API endpoint or CSV upload feature will not be
                sent to the feed and/or webhook.
              </Note>
              <Form.Select
                label="Send Realtime to Feed"
                name="feed_id"
                loading={feedsLoading}
                value={formdata.feed_id || ''}
                onChange={onChange}
                clearable
                options={(feeds?.data || []).map(f => ({
                  key: f.id,
                  value: f.id,
                  text: f.name,
                }))}
              />
              <Note>
                Data passed to the feed will be given a <code>utctime</code> of "now" and a <code>score</code> of 1.
              </Note>
              <Form.Select
                label="Send Realtime to Webhook"
                name="webhook_config_id"
                loading={webhooksLoading}
                value={formdata.webhook_config_id || ''}
                onChange={onChange}
                clearable
                options={(webhooks?.data || []).map(w => ({
                  key: w.id,
                  value: w.id,
                  text: w.name,
                }))}
              />
            </div>
          </Layout>
        </Form>
      </Segment>
    </>
  );
};

export default DatasetV2Settings;
