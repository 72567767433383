import { get, isEmpty, uniqBy, upperFirst } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Divider, DropdownItemProps, Form, Header, Icon, Label, Message, Popup } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useGetBigqueryTableQuery, useListBigqueryTablesQuery } from 'src/api/bigquery';
import { useListBlacklistsQuery } from 'src/api/blacklists';
import { useListDatasetsListsQuery, useListDatasetsQuery } from 'src/api/datasets';
import { useListFeedsQuery } from 'src/api/feeds';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListModelsQuery } from 'src/api/models';
import { useGetPipelineMetaValues, useSavePipelineMutation } from 'src/api/pipelines';
import { useListPipelineFilterSetsQuery, useSavePipelineFilterSetMutation } from 'src/api/pipelines-filter-sets';
import { useListSchedulesQuery } from 'src/api/schedules';
import ApiMessage from 'src/components/ApiMessage';
import BrooksCimaCertProperties from 'src/data/brooks-cima-cert-types.json';
import DsCensusProperties from 'src/data/ds-census-types.json';
import DsMetadataProperties from 'src/data/ds-metadata-types.json';
import { StyledFieldset } from 'src/styles';
import {
  BaseSchema,
  ComparisonOperatorOptions,
  DE_METADATA_FIELDS,
  LEAD_SCORE_ACCOUNT_IDS,
  ModelStage,
  Pipeline,
  PipelineExtractFilter,
  PipelineFilterSet,
  PipelineScheduleFrequency,
  SortDirectionNames,
  SortDirections,
  TimeRanges,
  TimezoneOptions,
  Weekday,
  WeekdayShortNames,
} from 'src/types';
import { LookaheadOptions } from '../../models/schedule/ScheduleImportForm';
import ConvertPipelineFiltersToSetModal from './ConvertPipelineFiltersToSetModal';
import DetachPipelineFilterSetModal from './DetachPipelineFilterSetModal';
import { Layout } from './style';

type ValidationErrors = {
  name?: string;
  scheduleEvery?: string;
  scheduleFrequency?: string;
  scheduleDays?: string;
  scheduleHour?: string;
  scheduleEndHour?: string;
  scheduleTimeZone?: string;
  extractColumns?: string;
  extractFilters?: string;
  extractLeadScoreMin?: string;
  extractLeadScoreMax?: string;
  extractSort?: string;
  extractTableId?: string;
  extractDatasetId?: string;
  extractListId?: string;
  engagementScheduleId?: string;
  engagementModelVersion?: string;
  engagementModelId?: string;
  ingestFeedId?: string;
};

const FrequencyOptions = [
  { key: PipelineScheduleFrequency.hours, value: PipelineScheduleFrequency.hours, text: 'Hour(s)' },
  { key: PipelineScheduleFrequency.days, value: PipelineScheduleFrequency.days, text: 'Day(s)' },
  { key: PipelineScheduleFrequency.weeks, value: PipelineScheduleFrequency.weeks, text: 'Week(s)' },
  // TODO: backend logic not implemented yet
  // { key: PipelineScheduleFrequency.months, value: PipelineScheduleFrequency.months, text: 'Month(s)' },
];

const getInitialFormdata = (pipeline: Pipeline): Pipeline => {
  const next = _cloneDeep(pipeline);

  // Set any missing defaults and/or apply data type conversions
  if (next.config.schedule.days === null) {
    next.config.schedule.days = [];
  }
  if (next.config.extract.filters === null) {
    next.config.extract.filters = [];
  }
  if (next.config.extract.table_id > 0 && !next.config.extract.is_legacy) {
    next.config.extract.is_legacy = true;
  }
  if (
    (next.config.extract.dataset_ids === null || next.config.extract.dataset_ids.length === 0) &&
    next.config.extract.dataset_id
  ) {
    next.config.extract.dataset_ids = [next.config.extract.dataset_id];
  }
  if (
    (next.config.extract.list_ids === null || next.config.extract.list_ids.length === 0) &&
    next.config.extract.list_id
  ) {
    next.config.extract.list_ids = [next.config.extract.list_id];
  }
  if (next.config.ingest.field_mappings === null) {
    next.config.ingest.field_mappings = [];
  }

  return next;
};

const getInitialFilterSetFormdata = (set: PipelineFilterSet | undefined): PipelineFilterSet => {
  const next = _cloneDeep(
    set || ({ id: 0, createdAt: '', createdById: 0, createdBy: null, name: '', filters: [] } as PipelineFilterSet)
  );

  // Set any missing defaults and/or apply data type conversions
  if (next.filters === null) {
    next.filters = [];
  }

  return next;
};

const hourToDate = (hour: number): Date | null => {
  if (typeof hour !== 'number' || hour < 0 || hour > 23) {
    return null;
  }

  const date = new Date();
  date.setHours(hour, 0, 0, 0);

  return date;
};

const EditPipelineForm = ({
  pipeline,
  isValid,
  setIsValid,
}: {
  pipeline: Pipeline;
  isValid: boolean;
  setIsValid: (isValid: boolean) => void;
}) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<Pipeline>(() => getInitialFormdata(pipeline));
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const [viewErrors, setViewErrors] = useState(false);
  const [saved, setSaved] = useState(true);
  const { data: tables, isLoading: tablesLoading } = useListBigqueryTablesQuery({ limit: 100, offset: 0 });
  const { data: datasets, isLoading: datasetsLoading } = useListDatasetsQuery({ limit: 100, offset: 0 });
  const { data: models, isLoading: modelsLoading } = useListModelsQuery({ limit: 100, offset: 0 });
  const { data: feeds, isLoading: feedsLoading } = useListFeedsQuery({ limit: 100, offset: 0 });
  const { data: blacklists, isLoading: blacklistsLoading } = useListBlacklistsQuery({ limit: 100, offset: 0 });
  const { data: schedules, isLoading: schedulesLoading } = useListSchedulesQuery({ limit: 100, offset: 0 });
  const { data: filterSets, isLoading: filterSetsLoading } = useListPipelineFilterSetsQuery({ limit: 100, offset: 0 });
  const { data: user } = useGetUserProfileQuery();
  const { mutateAsync: savePipeline, isLoading: saveLoading } = useSavePipelineMutation();
  const { mutateAsync: saveFilterSet, isLoading: saveFilterSetLoading } = useSavePipelineFilterSetMutation();
  const { data: selectedTable, isLoading: tableColumnsLoading } = useGetBigqueryTableQuery(
    Number(formdata.config.extract.table_id)
  );
  const selectedDatasets = useMemo(
    () => (formdata.config.extract.dataset_ids || []).map(id => datasets?.data.find(d => d.id === id)).filter(Boolean),
    [datasets?.data, formdata.config.extract.dataset_ids]
  );
  const { data: datasetsLists, isLoading: datasetListsLoading } = useListDatasetsListsQuery({
    datasetsIds: formdata.config.extract.dataset_ids || [],
    limit: 100,
    offset: 0,
  });

  const selectedFilterSet = useMemo(() => {
    return filterSets?.data.find(s => s.id === formdata.config.extract.filter_set_id);
  }, [filterSets?.data, formdata.config.extract.filter_set_id]);
  const [filterSetFormdata, setFilterSetFormdata] = useState<PipelineFilterSet>(() =>
    getInitialFilterSetFormdata(selectedFilterSet)
  );
  const [filterSetSaved, setFilterSetSaved] = useState(true);

  useEffect(() => {
    setFormdata(getInitialFormdata(pipeline));
  }, [pipeline]);

  useEffect(() => {
    setFilterSetFormdata(getInitialFilterSetFormdata(selectedFilterSet));
  }, [selectedFilterSet]);

  const { data: metaValues } = useGetPipelineMetaValues(pipeline.id);

  // TODO: This does not work when using back/forward browser buttons
  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [saved]);

  const showExtractLeadScore =
    !formdata.config.extract.is_legacy &&
    (Object.values(LEAD_SCORE_ACCOUNT_IDS).includes(pipeline.account_id) || process.env.NODE_ENV === 'development');

  const validate = useCallback(
    (p: Pipeline) => {
      let errors = {} as ValidationErrors;

      // General
      if (!p.name.trim()) {
        errors.name = 'Name is required';
      }

      if (!p.config.schedule.every) {
        errors.scheduleEvery = 'Every is required';
      }

      if (!p.config.schedule.frequency) {
        errors.scheduleFrequency = 'Frequency is required';
      }

      if (p.config.schedule.frequency === PipelineScheduleFrequency.weeks && isEmpty(p.config.schedule.days)) {
        errors.scheduleDays = 'Day of the week is required';
      }

      // if (p.config.schedule.hour < 0 || p.config.schedule.hour > 23) {
      //   errors.scheduleHour = 'At is invalid';
      // }
      if (
        p.config.schedule.frequency === PipelineScheduleFrequency.hours &&
        p.config.schedule.end_hour <= p.config.schedule.hour
      ) {
        errors.scheduleEndHour = 'To time must be after From time';
      }

      // Extract
      if (p.config.extract.is_legacy && p.config.extract.table_id === 0) {
        errors.extractTableId = 'Datasets v1 is required';
      } else if (
        !p.config.extract.is_legacy &&
        (p.config.extract.dataset_ids === null || p.config.extract.dataset_ids.length === 0)
      ) {
        errors.extractDatasetId = 'Dataset(s) is required';
      }

      if (isEmpty(p.config.extract.columns)) {
        errors.extractColumns = 'Columns is required';
      } else if (p.config.model.enabled) {
        if (!p.config.extract.columns.includes('firstname')) {
          errors.extractColumns = 'Engagement Model requires column: firstname';
        } else if (!p.config.extract.columns.includes('lastname')) {
          errors.extractColumns = 'Engagement Model requires column: lastname';
        } else if (!p.config.extract.columns.includes('zip')) {
          errors.extractColumns = 'Engagement Model requires column: zip';
        }
      }

      let fitlerError = '';
      p.config.extract.filters.forEach(f => {
        if (!f.column || !f.operator || (!['null', 'not_null'].includes(f.operator) && !f.value)) {
          fitlerError = 'Invalid filter(s). See errors below.';
        }
      });
      if (fitlerError) {
        errors.extractFilters = fitlerError;
      }

      if (showExtractLeadScore && p.config.extract.lead_score) {
        if (!p.config.extract.columns.includes('lastname')) {
          errors.extractColumns = 'Lead Score requires column: lastname';
        } else if (!p.config.extract.columns.includes('city')) {
          errors.extractColumns = 'Lead Score requires column: city';
        } else if (!p.config.extract.columns.includes('state')) {
          errors.extractColumns = 'Lead Score requires column: state';
        } else if (!p.config.extract.columns.includes('zip')) {
          errors.extractColumns = 'Lead Score requires column: zip';
        }

        if (!p.config.extract.lead_score_min) {
          errors.extractLeadScoreMin = 'Lead Score min is required';
        }
        if (Number(p.config.extract.lead_score_min) > 100) {
          errors.extractLeadScoreMin = 'Lead Score min cannot exceed 100';
        }

        if (p.config.extract.lead_score_type === 'between' && !p.config.extract.lead_score_max) {
          errors.extractLeadScoreMax = 'Lead Score max is required';
        }
        if (p.config.extract.lead_score_type === 'between' && Number(p.config.extract.lead_score_max) > 100) {
          errors.extractLeadScoreMax = 'Lead Score max cannot exceed 100';
        }

        if (
          p.config.extract.lead_score_type === 'between' &&
          Number(p.config.extract.lead_score_max) <= Number(p.config.extract.lead_score_min)
        ) {
          errors.extractLeadScoreMax = 'Lead Score max must be greater than min';
        }
      }

      if (p.config.extract.is_legacy && !p.config.extract.sort_column) {
        errors.extractSort = 'Sort is required';
      }

      // Model
      if (p.config.model.enabled) {
        if (!p.config.model.engagement_modeling.model_version) {
          errors.engagementModelVersion = 'Model version is required';
        }

        if (!p.config.model.engagement_modeling.model_id) {
          errors.engagementModelId = 'Model is required';
        }

        if (!p.config.model.engagement_modeling.schedule_id) {
          errors.engagementScheduleId = 'Schedule is required';
        }
      }

      // Ingest
      if (p.config.ingest.enabled) {
        if (!p.config.ingest.feed_id) {
          errors.ingestFeedId = 'Feed is required';
        }
      }

      const isValid = isEmpty(errors);

      if (!viewErrors) {
        errors = {} as ValidationErrors;
      }

      setErrors(errors);
      setIsValid(isValid);

      return isValid;
    },
    [showExtractLeadScore, viewErrors, setIsValid]
  );

  const onSubmit = useCallback(
    async (pipeline: Pipeline, force = false) => {
      if (saved && !force) {
        return;
      }

      setApiMessage(undefined);

      try {
        await savePipeline({ pipeline });
        setSaved(true);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [savePipeline, saved]
  );

  useEffect(() => {
    validate(formdata);
  }, [formdata, validate]);

  const onChange = useCallback((_, { checked, name, value }) => {
    setFormdata(prev => {
      let v = typeof checked !== 'undefined' ? checked : value;
      if (
        [
          'config.extract.table_id',
          'config.extract.filter_set_id',
          'config.extract.limit',
          'config.extract.lead_score_min',
          'config.extract.lead_score_max',
          'config.model.engagement_modeling.hourly_capacity',
          'config.model.engagement_modeling.max_hourly_capacity',
          'config.schedule.every',
        ].includes(name)
      ) {
        v = Number(v);
        if (Number.isNaN(v)) {
          v = 0;
        }
      }

      const next = _cloneDeep(prev);
      _set(next, name, v);
      return next;
    });
    setSaved(false);
  }, []);

  const onChangeFilterSet = useCallback((_, { checked, name, value }) => {
    setFilterSetFormdata(prev => {
      const v = typeof checked !== 'undefined' ? checked : value;
      const next = _cloneDeep(prev);
      _set(next, name, v);
      return next;
    });
    setFilterSetSaved(false);
  }, []);

  const onClickSaveFilterSet = useCallback(async () => {
    setApiMessage(undefined);
    try {
      await saveFilterSet({ set: filterSetFormdata });
      setFilterSetSaved(true);
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [filterSetFormdata, saveFilterSet]);

  const [selectedModel] = models?.data.filter(m => m.id === formdata.config.model.engagement_modeling.model_id) || [];

  const selectedModelVersion = formdata.config.model.engagement_modeling.model_version;

  const modelVersions: DropdownItemProps[] = useMemo(() => {
    if (!selectedModel?.versions) return [];

    const versions = selectedModel.versions.reduce((acc, v) => {
      if (v.stage === ModelStage.Archived) return acc;

      return [
        ...acc,
        {
          key: v.version,
          text: `${v.version} ${ModelStage[v.stage]}`,
          value: v.version,
        },
      ];
    }, [] as DropdownItemProps[]);

    // First valid option
    const version = selectedModel.versions.find(v => v.version === versions[0].value);
    if (!selectedModelVersion && version?.version) {
      onChange(null, { name: 'config.model.engagement_modeling.model_version', value: version?.version });
    }

    return versions;
  }, [onChange, selectedModel?.versions, selectedModelVersion]);

  const SelectedTableColumns = useMemo(() => {
    let columns: any[] = [];
    if (formdata.config.extract.is_legacy) {
      columns = columns.concat(
        (selectedTable?.columns || []).map(c => ({
          key: c.name,
          value: c.name,
          text: c.name,
        }))
      );
    } else {
      columns = columns.concat([
        { key: 'created_at', value: 'created_at', text: 'created_at' },

        ...BaseSchema.map(f => ({
          key: f.name,
          value: f.name,
          text: f.name,
        })),

        ...selectedDatasets
          .map(d =>
            (d?.custom_fields || []).map(f => ({
              key: f.name,
              value: f.name,
              text: f.name,
            }))
          )
          .flat(),

        // dataset_documents.metadata
        {
          key: 'metadata.phone_type',
          value: 'metadata.phone_type',
          text: 'Phone Type',
          description: 'Meta',
        },

        // de_metadata
        ...DE_METADATA_FIELDS.map(k => ({
          key: `de_metadata.${k}`,
          value: `de_metadata.${k}`,
          text: k.split('_').map(upperFirst).join(' '),
          description: 'Meta',
        })),

        // Enrichment
        ...(user?.active_account?.brooks?.censusProperties?.map(p => ({
          key: `enrichment_data.census.${p}`,
          value: `enrichment_data.census.${p}`,
          text: `census.${p}`,
          description: 'Enrichment',
        })) || []),
        ...(user?.active_account?.brooks?.phoneLookupProperties?.map(p => ({
          key: `enrichment_data.phoneLookup.${p}`,
          value: `enrichment_data.phoneLookup.${p}`,
          text: p,
          description: 'Enrichment',
        })) || []),
        ...(user?.active_account?.brooks?.cimaProperties?.map(p => {
          const prop = get(BrooksCimaCertProperties, p);

          return {
            key: `enrichment_data.cimaCert.${p}`,
            value: `enrichment_data.cimaCert.${p}`,
            text: prop.friendlyName || p,
            description: 'Enrichment',
          };
        }) || []),

        // LeadScore+
        {
          key: 'leadscore_prediction.Outcome',
          value: 'leadscore_prediction.Outcome',
          text: 'Outcome',
          description: 'LeadScore',
        },
        {
          key: 'leadscore_prediction.PositiveScore',
          value: 'leadscore_prediction.PositiveScore',
          text: 'Positive Score',
          description: 'LeadScore',
        },
        {
          key: 'leadscore_prediction.NegativeScore',
          value: 'leadscore_prediction.NegativeScore',
          text: 'Negative Score',
          description: 'LeadScore',
        },
      ]);
    }

    return uniqBy(columns, 'key');
  }, [
    formdata.config.extract.is_legacy,
    selectedDatasets,
    selectedTable?.columns,
    user?.active_account?.brooks?.censusProperties,
    user?.active_account?.brooks?.cimaProperties,
    user?.active_account?.brooks?.phoneLookupProperties,
  ]);

  useEffect(() => {
    if (!formdata.config.extract.columns || formdata.config.extract.columns?.length > 0) {
      return;
    }

    const nextColumns = SelectedTableColumns.filter(c =>
      ['phone', 'firstname', 'lastname', 'zip'].includes(c.value as string)
    ).map(c => c.value);
    if (nextColumns.length === 0) {
      return;
    }

    onChange(null, {
      name: 'config.extract.columns',
      value: nextColumns,
    });
  }, [SelectedTableColumns, formdata.config.extract.columns, onChange]);

  const toggleViewErrors = () => {
    setViewErrors(prev => !prev);
  };

  const frequencyDayChooserTitle =
    formdata.config.schedule.frequency !== PipelineScheduleFrequency.weeks ? 'Days of the Week' : 'Day of the Week';

  const frequencyDayChooserChange = useCallback(
    (i: Weekday) => {
      const prev = formdata.config.schedule.days;

      let next = [] as Weekday[];
      if (formdata.config.schedule.frequency === PipelineScheduleFrequency.weeks) {
        next = prev.includes(i) ? [] : [i];
      } else {
        if (prev.includes(i)) {
          next = prev.filter(v => v !== i);
        } else {
          next = prev.concat([i]);
        }
      }

      onChange(null, { name: 'config.schedule.days', value: next });
    },
    [formdata.config.schedule.days, formdata.config.schedule.frequency, onChange]
  );

  const renderFilter = useCallback(
    (f: PipelineExtractFilter, i: number) => {
      let operatorOptions = ComparisonOperatorOptions.filter(opt =>
        ['__created_at', 'created_at', 'de_metadata.recent_call_date'].includes(f.column)
          ? ['gt', 'gte', 'lt', 'lte', 'time_after', 'time_before'].includes(opt.value)
          : f.column === 'metadata.phone_type'
          ? ['eq', 'neq', 'in'].includes(opt.value)
          : formdata.config.extract.dataset_ids === null ||
            formdata.config.extract.dataset_ids.length === 0 ||
            !['not_in', 'like', 'not_like', 'not_null'].includes(opt.value)
      );

      let prop = null;
      let propOptions: DropdownItemProps[] = [];

      const cimaProp = get(BrooksCimaCertProperties, f.column.replace('enrichment_data.cimaCert.', ''));
      if (cimaProp) {
        prop = cimaProp;
      }

      const censusProp = get(DsCensusProperties, f.column.replace('enrichment_data.census.', ''));
      if (censusProp) {
        prop = censusProp;
      }

      const metaProp = get(DsMetadataProperties, f.column.replace('metadata.', ''));
      if (metaProp) {
        prop = metaProp;
      }

      if (prop && typeof prop !== 'string' && prop !== null) {
        let propOperators: string[] = [];
        switch (prop.type) {
          case 'enum':
            propOperators = ['eq', 'neq', 'in', 'not_in'];
            break;
          case 'integer':
          case 'decimal':
            propOperators = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'];
            break;
        }

        if (propOperators.length > 0) {
          operatorOptions = operatorOptions.filter(o => propOperators.includes(o.value as string));
        }

        if (prop.type === 'enum' && prop.enum && prop.enum.length > 0) {
          propOptions = prop.enum.map((e: { value: string; text: string }) => ({ ...e, key: e.value }));
        }
      }

      const multiple = f.operator === 'in' || f.operator === 'not_in';

      if (typeof metaValues?.[f.column] !== 'undefined') {
        metaValues?.[f.column].forEach(value => {
          propOptions.push({ key: value, value, text: value });
        });
      }

      return (
        <Form.Group key={`filter-${i}`} widths="equal">
          <Form.Select
            fluid
            placeholder={
              !formdata.config.extract.table_id &&
              (formdata.config.extract.dataset_ids === null || formdata.config.extract.dataset_ids.length === 0)
                ? 'No dataset selected'
                : 'Column'
            }
            options={SelectedTableColumns}
            loading={tableColumnsLoading || datasetsLoading}
            value={f.column || ''}
            name={`config.extract.filters[${i}].column`}
            onChange={(e, d) => {
              onChange(e, d);

              // Force user to select new values for `operator` + `value` if they change the column
              onChange(e, { name: `config.extract.filters[${i}].operator`, value: '' });
              onChange(e, { name: `config.extract.filters[${i}].value`, value: '' });
            }}
            error={f.column === '' ? 'Column is required' : undefined}
          />

          <Form.Select
            fluid
            placeholder="Operator"
            options={operatorOptions}
            value={f.operator || ''}
            name={`config.extract.filters[${i}].operator`}
            onChange={(e, d) => {
              onChange(e, d);

              // If changing to a non-multiple operator, we need to clear the extra options from `value`
              const nextMultiple = d.value === 'in' || d.value === 'not_in';
              onChange(e, {
                name: `config.extract.filters[${i}].value`,
                value: (nextMultiple ? f.value : (f.value || '').split(',')[0]) || '',
              });
            }}
            error={f.operator === '' ? 'Operator is required' : undefined}
          />

          {propOptions.length > 0 ? (
            <Form.Select
              fluid
              clearable
              multiple={multiple}
              placeholder={multiple ? 'Value(s)' : 'Value'}
              value={multiple ? (f.value && (f.value || '').split(',')) || [] : (f.value || '').split(',')[0] || ''}
              name={`config.extract.filters[${i}].value`}
              options={propOptions}
              onChange={(e, d) => {
                onChange(e, { ...d, value: multiple ? (d.value as string[]).join(',') : (d.value as string) });
              }}
            />
          ) : ['time_after', 'time_before'].includes(f.operator) ? (
            <Form.Select
              fuild
              placeholder="Value"
              value={f.value || ''}
              name={`config.extract.filters[${i}].value`}
              onChange={onChange}
              options={TimeRanges.map(r => ({ key: String(r.value), value: String(r.value), text: r.text + ' Ago' }))}
              error={f.value === '' ? 'Value is required' : undefined}
            />
          ) : (
            !['null', 'not_null'].includes(f.operator) && (
              <Form.Input
                fluid
                placeholder="Value"
                value={f.value || ''}
                name={`config.extract.filters[${i}].value`}
                onChange={onChange}
                error={f.value === '' ? 'Value is required' : undefined}
              />
            )
          )}

          <Button
            type="button"
            color="red"
            icon
            onClick={() => {
              onChange(null, {
                name: 'config.extract.filters',
                value: formdata.config.extract.filters.filter((_, j) => i !== j),
              });
            }}
          >
            <Icon name="trash" />
          </Button>
        </Form.Group>
      );
    },
    [
      formdata.config.extract.table_id,
      formdata.config.extract.dataset_ids,
      formdata.config.extract.filters,
      SelectedTableColumns,
      tableColumnsLoading,
      datasetsLoading,
      onChange,
      metaValues,
    ]
  );

  const renderFilterSetFilter = useCallback(
    (f: PipelineExtractFilter, i: number) => {
      let operatorOptions = ComparisonOperatorOptions.filter(opt =>
        ['__created_at', 'created_at', 'de_metadata.recent_call_date'].includes(f.column)
          ? ['gt', 'gte', 'lt', 'lte', 'time_after', 'time_before'].includes(opt.value)
          : f.column === 'metadata.phone_type'
          ? ['eq', 'neq', 'in'].includes(opt.value)
          : formdata.config.extract.dataset_ids === null ||
            formdata.config.extract.dataset_ids.length === 0 ||
            !['not_in', 'like', 'not_like', 'not_null'].includes(opt.value)
      );

      let prop = null;
      let propOptions: DropdownItemProps[] = [];

      const cimaProp = get(BrooksCimaCertProperties, f.column.replace('enrichment_data.cimaCert.', ''));
      if (cimaProp) {
        prop = cimaProp;
      }

      const censusProp = get(DsCensusProperties, f.column.replace('enrichment_data.census.', ''));
      if (censusProp) {
        prop = censusProp;
      }

      const metaProp = get(DsMetadataProperties, f.column.replace('metadata.', ''));
      if (metaProp) {
        prop = metaProp;
      }

      if (prop && typeof prop !== 'string' && prop !== null) {
        let propOperators: string[] = [];
        switch (prop.type) {
          case 'enum':
            propOperators = ['eq', 'neq', 'in', 'not_in'];
            break;
          case 'integer':
          case 'decimal':
            propOperators = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'];
            break;
        }

        if (propOperators.length > 0) {
          operatorOptions = operatorOptions.filter(o => propOperators.includes(o.value as string));
        }

        if (prop.type === 'enum' && prop.enum && prop.enum.length > 0) {
          propOptions = prop.enum.map((e: { value: string; text: string }) => ({ ...e, key: e.value }));
        }
      }

      const multiple = f.operator === 'in' || f.operator === 'not_in';

      return (
        <Form.Group key={`filterSet-${i}`} widths="equal">
          <Form.Select
            disabled={user?.role !== 'admin'}
            fluid
            placeholder={
              !formdata.config.extract.table_id &&
              (formdata.config.extract.dataset_ids === null || formdata.config.extract.dataset_ids.length === 0)
                ? 'No dataset selected'
                : 'Column'
            }
            options={SelectedTableColumns}
            loading={tableColumnsLoading || datasetsLoading}
            value={f.column || ''}
            name={`filters[${i}].column`}
            onChange={(e, d) => {
              onChangeFilterSet(e, d);

              // Force user to select new values for `operator` + `value` if they change the column
              onChangeFilterSet(e, { name: `filters[${i}].operator`, value: '' });
              onChangeFilterSet(e, { name: `filters[${i}].value`, value: '' });
            }}
            error={f.column === '' ? 'Column is required' : undefined}
          />

          <Form.Select
            disabled={user?.role !== 'admin'}
            fluid
            placeholder="Operator"
            options={operatorOptions}
            value={f.operator || ''}
            name={`filters[${i}].operator`}
            onChange={(e, d) => {
              onChangeFilterSet(e, d);

              // If changing to a non-multiple operator, we need to clear the extra options from `value`
              const nextMultiple = d.value === 'in' || d.value === 'not_in';
              onChangeFilterSet(e, {
                name: `filters[${i}].value`,
                value: (nextMultiple ? f.value : (f.value || '').split(',')[0]) || '',
              });
            }}
            error={f.operator === '' ? 'Operator is required' : undefined}
          />

          {propOptions.length > 0 ? (
            <Form.Select
              disabled={user?.role !== 'admin'}
              fluid
              clearable
              multiple={multiple}
              placeholder={multiple ? 'Value(s)' : 'Value'}
              value={multiple ? (f.value && (f.value || '').split(',')) || [] : (f.value || '').split(',')[0] || ''}
              name={`filters[${i}].value`}
              options={propOptions}
              onChange={(e, d) => {
                onChangeFilterSet(e, { ...d, value: multiple ? (d.value as string[]).join(',') : (d.value as string) });
              }}
            />
          ) : ['time_after', 'time_before'].includes(f.operator) ? (
            <Form.Select
              disabled={user?.role !== 'admin'}
              fuild
              value={f.value || ''}
              name={`filters[${i}].value`}
              onChange={onChangeFilterSet}
              options={TimeRanges.map(r => ({ key: String(r.value), value: String(r.value), text: r.text + ' Ago' }))}
              error={f.value === '' ? 'Value is required' : undefined}
            />
          ) : (
            !['null', 'not_null'].includes(f.operator) && (
              <Form.Input
                disabled={user?.role !== 'admin'}
                fluid
                value={f.value || ''}
                name={`filters[${i}].value`}
                onChange={onChangeFilterSet}
                error={f.value === '' ? 'Value is required' : undefined}
              />
            )
          )}

          {user?.role === 'admin' && (
            <Button
              type="button"
              color="red"
              icon
              onClick={() => {
                onChangeFilterSet(null, {
                  name: 'filters',
                  value: filterSetFormdata.filters.filter((_, j) => i !== j),
                });
              }}
            >
              <Icon name="trash" />
            </Button>
          )}
        </Form.Group>
      );
    },
    [
      SelectedTableColumns,
      datasetsLoading,
      filterSetFormdata.filters,
      formdata.config.extract.dataset_ids,
      formdata.config.extract.table_id,
      onChangeFilterSet,
      tableColumnsLoading,
      user?.role,
    ]
  );

  const getDatasetName = (id: string) => {
    for (const selectedDataset of selectedDatasets ?? []) {
      if (id === selectedDataset?.id) return selectedDataset.name;
    }
    return '';
  };

  const hourlyCapacityRecommendedMax = (user?.active_account.voice?.outbound_limit_max || 60) * 60 * 1.2;

  return (
    <Form style={{ position: 'relative' }} onSubmit={() => onSubmit(formdata, true)}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Popup
            trigger={
              <Button
                size="mini"
                compact
                color="red"
                style={{ marginLeft: '0.5rem' }}
                type="button"
                onClick={toggleViewErrors}
              >
                <Icon name="dont" />
                Invalid
                <Icon name={viewErrors ? 'eye' : 'eye slash'} style={{ marginLeft: '0.5rem', marginRight: 0 }} />
              </Button>
            }
          >
            {viewErrors ? 'Hide' : 'Show'} validation errors
          </Popup>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <Layout>
        <div style={{ gridArea: 'name' }}>
          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />
        </div>

        <div style={{ gridArea: 'sch' }}>
          <Form.Group>
            <Form.Input
              label="Every"
              style={{ width: 50 }}
              value={formdata.config.schedule.every || ''}
              name="config.schedule.every"
              onChange={onChange}
              error={errors.scheduleEvery}
            />

            <Form.Select
              label="Frequency"
              options={FrequencyOptions}
              name="config.schedule.frequency"
              value={formdata.config.schedule.frequency || ''}
              onChange={onChange}
              style={{ width: 180, minWidth: 0 }}
            />

            {(formdata.config.schedule.frequency === PipelineScheduleFrequency.weeks ||
              formdata.config.schedule.frequency === PipelineScheduleFrequency.hours ||
              (formdata.config.schedule.frequency === PipelineScheduleFrequency.days &&
                formdata.config.schedule.every === 1)) && (
              <Form.Field error={!!errors.scheduleDays}>
                <label>{frequencyDayChooserTitle}</label>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    height: 38,
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  {WeekdayShortNames.map((dow, i) => (
                    <Button
                      key={dow}
                      type="button"
                      circular
                      size="small"
                      style={{
                        padding: 0,
                        width: 28,
                        height: 28,
                        margin: 0,
                        ...(!Boolean(errors.scheduleDays) ? {} : { backgroundColor: '#fdf1f1', color: '#9f3a38' }),
                      }}
                      color={formdata.config.schedule.days.includes(i) ? 'blue' : undefined}
                      onClick={() => frequencyDayChooserChange(i)}
                    >
                      {dow[0].toUpperCase()}
                    </Button>
                  ))}
                </div>
                {errors.scheduleDays && (
                  <Label prompt color="red" pointing>
                    {errors.scheduleDays}
                  </Label>
                )}
              </Form.Field>
            )}

            <Form.Field style={{ width: 135 }} error={!!errors.scheduleHour}>
              <ReactDatePicker
                selected={hourToDate(formdata.config.schedule.hour)}
                onChange={date => {
                  if (date === null) return;
                  onChange(null, { name: 'config.schedule.hour', value: date.getHours() });
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Hour"
                dateFormat="h:mm aa"
                popperPlacement="bottom"
                customInput={
                  <Form.Input
                    label={formdata.config.schedule.frequency === PipelineScheduleFrequency.hours ? 'From' : 'At'}
                    icon="clock"
                  />
                }
              />
              {errors.scheduleHour && (
                <Label prompt color="red" pointing>
                  {errors.scheduleDays}
                </Label>
              )}
            </Form.Field>
            {formdata.config.schedule.frequency === PipelineScheduleFrequency.hours && (
              <Form.Field style={{ width: 135 }} error={!!errors.scheduleEndHour}>
                <ReactDatePicker
                  selected={hourToDate(formdata.config.schedule.end_hour)}
                  onChange={date => {
                    if (date === null) return;
                    onChange(null, { name: 'config.schedule.end_hour', value: date.getHours() });
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Hour"
                  dateFormat="h:mm aa"
                  popperPlacement="bottom"
                  customInput={<Form.Input label="To" icon="clock" />}
                />
                {errors.scheduleEndHour && (
                  <Label prompt color="red" pointing>
                    {errors.scheduleEndHour}
                  </Label>
                )}
              </Form.Field>
            )}
          </Form.Group>
          <Form.Group style={{ marginBottom: 0 }}>
            <Form.Select
              label="Timezone"
              clearable
              placeholder="America/Los_Angeles"
              fluid
              selection
              name="config.schedule.timezone"
              options={TimezoneOptions}
              value={formdata.config.schedule.timezone || ''}
              onChange={onChange}
              error={!!errors.scheduleTimeZone}
              upward={false}
            />
          </Form.Group>
        </div>

        <div style={{ gridArea: 'ext' }}>
          <Header>Extract</Header>

          <Form.Checkbox
            toggle
            label="Legacy?"
            checked={formdata.config.extract.is_legacy}
            name="config.extract.is_legacy"
            onChange={(e, d) => {
              onChange(e, d);
              if (!d.checked) {
                onChange(null, { name: 'config.extract.table_id', value: 0 });
              }
            }}
          />

          {formdata.config.extract.is_legacy ? (
            <Form.Select
              label="Dataset v1"
              clearable
              placeholder="Select a dataset"
              options={
                tables?.data.map(t => ({
                  key: t.id,
                  value: t.id,
                  text: t.name,
                  description: 'v1',
                })) || []
              }
              loading={tablesLoading}
              value={formdata.config.extract.table_id || ''}
              name="config.extract.table_id"
              onChange={onChange}
              error={errors.extractTableId}
            />
          ) : (
            <>
              <Form.Select
                label="Dataset(s)"
                multiple
                clearable
                placeholder="Select a dataset"
                options={
                  datasets?.data.map(d => ({
                    key: d.id,
                    value: d.id,
                    text: d.name,
                    description: 'v2',
                  })) || []
                }
                loading={datasetsLoading}
                value={formdata.config.extract.dataset_ids || []}
                name="config.extract.dataset_ids"
                onChange={onChange}
                error={errors.extractDatasetId}
              />

              {(formdata.config.extract.dataset_ids === null || formdata.config.extract.dataset_ids.length !== 0) && (
                <Form.Select
                  label="List(s)"
                  multiple
                  clearable
                  placeholder="Select a list"
                  options={
                    datasetsLists?.data.map(l => ({
                      key: l.id,
                      value: l.id,
                      text: l.name,
                      description: getDatasetName(l.dataset_id),
                    })) || []
                  }
                  loading={datasetListsLoading}
                  value={formdata.config.extract.list_ids || []}
                  name="config.extract.list_ids"
                  onChange={onChange}
                  error={errors.extractListId}
                />
              )}
            </>
          )}

          <Form.Select
            label="Columns"
            clearable
            placeholder={
              !formdata.config.extract.table_id &&
              (formdata.config.extract.dataset_ids === null || formdata.config.extract.dataset_ids.length === 0)
                ? 'No dataset selected'
                : undefined
            }
            multiple
            options={SelectedTableColumns}
            loading={tableColumnsLoading || datasetsLoading}
            value={formdata.config.extract.columns || []}
            name="config.extract.columns"
            onChange={onChange}
            error={errors.extractColumns}
          />

          <Form.Field>
            <label>Filters</label>
          </Form.Field>

          <StyledFieldset>
            <legend>Admin only</legend>

            {user?.role === 'admin' && (
              <Form.Group>
                <Form.Select
                  loading={filterSetsLoading}
                  clearable
                  placeholder="Select Filter Set"
                  name="config.extract.filter_set_id"
                  value={formdata.config.extract.filter_set_id || ''}
                  onChange={onChange}
                  options={
                    filterSets?.data?.map(s => ({
                      key: s.id,
                      value: s.id,
                      text: s.name,
                    })) || []
                  }
                />

                {selectedFilterSet ? (
                  <>
                    <DetachPipelineFilterSetModal disabled={!saved || !filterSetSaved} pipelineID={pipeline.id} />
                    {!filterSetSaved && (
                      <Form.Button type="button" onClick={onClickSaveFilterSet} loading={saveFilterSetLoading}>
                        Save
                      </Form.Button>
                    )}
                  </>
                ) : (
                  formdata.config.extract.filters.length > 0 && (
                    <ConvertPipelineFiltersToSetModal disabled={!saved} pipelineID={pipeline.id} />
                  )
                )}
              </Form.Group>
            )}

            {selectedFilterSet && (
              <>
                {user?.role === 'admin' && (
                  <>
                    <Form.Input
                      label="Filter Set Name"
                      value={filterSetFormdata.name}
                      onChange={onChangeFilterSet}
                      name="name"
                    />

                    <Form.Field>
                      <label>Filters</label>
                    </Form.Field>
                  </>
                )}

                {filterSetFormdata?.filters?.map(renderFilterSetFilter)}

                {user?.role === 'admin' && (
                  <Button
                    type="button"
                    compact
                    size="mini"
                    color="blue"
                    style={{ marginBottom: '1rem' }}
                    onClick={() => {
                      onChangeFilterSet(null, {
                        name: 'filters',
                        value: [...filterSetFormdata.filters, { column: '', operator: '', value: '' }],
                      });
                    }}
                  >
                    <Icon name="plus" /> Add Filter
                  </Button>
                )}
              </>
            )}
          </StyledFieldset>

          {errors.extractFilters && (
            <Message error visible>
              {errors.extractFilters}
            </Message>
          )}

          {formdata.config.extract.filters.map(renderFilter)}

          <Button
            type="button"
            compact
            size="mini"
            color="blue"
            style={{ marginBottom: '1rem' }}
            onClick={() => {
              onChange(null, {
                name: 'config.extract.filters',
                value: [...formdata.config.extract.filters, { column: '', operator: '', value: '' }],
              });
            }}
          >
            <Icon name="plus" /> Add Filter
          </Button>

          {showExtractLeadScore && (
            <Form.Checkbox
              label="Include Lead Score?"
              checked={formdata.config.extract.lead_score || false}
              name="config.extract.lead_score"
              onChange={onChange}
            />
          )}

          {showExtractLeadScore && formdata.config.extract.lead_score && (
            <Form.Group widths="equal" style={{ alignItems: 'flex-end' }}>
              <Form.Select
                fluid
                label="Lead Score"
                options={[
                  { key: 'eq', value: 'eq', text: 'Equals' },
                  { key: 'gte', value: 'gte', text: 'Greater Than or Equal To' },
                  { key: 'between', value: 'between', text: 'Between' },
                ]}
                loading={modelsLoading}
                value={formdata.config.extract.lead_score_type || 'eq'}
                name="config.extract.lead_score_type"
                onChange={onChange}
              />

              <Form.Input
                fluid
                label=" "
                placeholder="0"
                name="config.extract.lead_score_min"
                onChange={onChange}
                value={formdata.config.extract.lead_score_min || ''}
                error={!!errors.extractLeadScoreMin}
              />

              {formdata.config.extract.lead_score_type === 'between' && (
                <Form.Input
                  fluid
                  label=" "
                  placeholder="0"
                  name="config.extract.lead_score_max"
                  onChange={onChange}
                  value={formdata.config.extract.lead_score_max || ''}
                  error={!!errors.extractLeadScoreMax}
                />
              )}
            </Form.Group>
          )}

          {(errors.extractLeadScoreMin || errors.extractLeadScoreMax) && (
            <Message error visible>
              <Message.Content>{errors.extractLeadScoreMin || errors.extractLeadScoreMax}</Message.Content>
            </Message>
          )}

          {formdata.config.extract.table_id > 0 && (
            <Form.Group widths="equal">
              <Form.Select
                label="Sort"
                placeholder={
                  !formdata.config.extract.table_id &&
                  (formdata.config.extract.dataset_ids === null || formdata.config.extract.dataset_ids.length === 0)
                    ? 'No dataset selected'
                    : undefined
                }
                options={SelectedTableColumns}
                loading={tableColumnsLoading || datasetsLoading}
                value={formdata.config.extract.sort_column || ''}
                name="config.extract.sort_column"
                onChange={onChange}
                error={errors.extractSort}
              />

              <Form.Select
                label="Direction"
                value={formdata.config.extract.sort_direction}
                options={SortDirections.map((d, i) => ({ key: d, value: d, text: SortDirectionNames[i] }))}
                name="config.extract.sort_direction"
                onChange={onChange}
              />
            </Form.Group>
          )}

          <Form.Select
            label="Filter Blacklists"
            options={
              blacklists?.data.map(b => ({
                key: b.id,
                value: b.id,
                text: b.name,
              })) || []
            }
            multiple
            loading={blacklistsLoading}
            value={formdata.config.extract.blacklist_ids || []}
            name="config.extract.blacklist_ids"
            onChange={onChange}
          />

          <Form.Checkbox
            label="Exclude Connects?"
            checked={formdata.config.extract.exclude_connects || false}
            name="config.extract.exclude_connects"
            onChange={onChange}
          />

          {!formdata.config.extract.exclude_connects && (
            <Message error visible>
              <Message.Header>Warning!</Message.Header>
              <Message.Content>
                We <strong>strongly</strong> recommend that you <strong>ENABLE</strong> the Exclude Connects option.
                Disabling this option will lead to contacting customers which have already been connected to.
              </Message.Content>
            </Message>
          )}

          <Form.Input
            label="Limit"
            placeholder="No Limit"
            name="config.extract.limit"
            onChange={onChange}
            value={formdata.config.extract.limit || ''}
          />
        </div>

        <div style={{ gridArea: 'mod' }}>
          <Header>Model</Header>

          <Form.Checkbox
            toggle
            label="Enabled"
            checked={formdata.config.model.enabled}
            name="config.model.enabled"
            onChange={onChange}
          />

          <div style={{ opacity: formdata.config.model.enabled ? 1 : 0.25 }}>
            <Form.Group widths="equal">
              <Form.Select
                label="Engagement Model"
                options={
                  models?.data.map(m => ({
                    key: m.id,
                    value: m.id,
                    text: m.name,
                  })) || []
                }
                loading={modelsLoading}
                value={formdata.config.model.engagement_modeling.model_id || ''}
                name="config.model.engagement_modeling.model_id"
                onChange={onChange}
                error={errors.engagementModelId}
              />

              <Form.Select
                label="Version"
                options={modelVersions}
                loading={modelsLoading}
                value={formdata.config.model.engagement_modeling.model_version || ''}
                name="config.model.engagement_modeling.model_version"
                onChange={onChange}
                error={errors.engagementModelVersion}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Select
                label="Lookahead"
                options={LookaheadOptions}
                value={formdata.config.model.engagement_modeling.lookahead}
                name="config.model.engagement_modeling.lookahead"
                onChange={onChange}
              />

              <Form.Input
                fluid
                label="Hourly Capacity"
                placeholder="Auto"
                value={formdata.config.model.engagement_modeling.hourly_capacity || ''}
                name="config.model.engagement_modeling.hourly_capacity"
                onChange={onChange}
              />

              <Form.Input
                fluid
                placeholder={hourlyCapacityRecommendedMax}
                label="Max Hourly Capacity"
                value={formdata.config.model.engagement_modeling.max_hourly_capacity || ''}
                name="config.model.engagement_modeling.max_hourly_capacity"
                onChange={onChange}
                disabled={!!formdata.config.model.engagement_modeling.hourly_capacity}
              />
            </Form.Group>

            {(Number(formdata.config.model.engagement_modeling.hourly_capacity) > hourlyCapacityRecommendedMax ||
              Number(formdata.config.model.engagement_modeling.max_hourly_capacity) > hourlyCapacityRecommendedMax) && (
              <Message warning visible>
                It is recomended that the hourly capacity does not exceed {hourlyCapacityRecommendedMax}
              </Message>
            )}

            <Form.Select
              label="Schedule"
              options={
                schedules?.data.map(t => ({
                  key: t.id,
                  value: t.id,
                  text: t.name,
                })) || []
              }
              clearable
              loading={schedulesLoading}
              value={formdata.config.model.engagement_modeling.schedule_id || ''}
              name="config.model.engagement_modeling.schedule_id"
              onChange={onChange}
              error={errors.engagementScheduleId}
            />

            {/* <Divider />

          <Message warning visible>
            Lead Scoring Coming Soon
          </Message>

          <Form.Checkbox
            label="Append Lead Score?"
            checked={formdata.config.model.lead_scoring.enabled || false}
            name="config.model.lead_scoring.enabled"
            onChange={onChange}
            disabled
          />

          <Form.Select label="Lead Score Model" options={[]} disabled={!formdata.config.model.lead_scoring.enabled} /> */}
          </div>
        </div>

        <div style={{ gridArea: 'ing' }}>
          <Header>Ingest</Header>

          <Form.Checkbox
            toggle
            label="Enabled"
            checked={formdata.config.ingest.enabled}
            name="config.ingest.enabled"
            onChange={onChange}
          />

          <div style={{ opacity: formdata.config.ingest.enabled ? 1 : 0.25 }}>
            <Message warning visible>
              Field Renaming Coming Soon
            </Message>

            <Form.Checkbox
              disabled
              label="Rename Fields?"
              checked={formdata.config.ingest.rename_fields}
              name="config.ingest.rename_fields"
              onChange={onChange}
            />

            <div style={{ opacity: formdata.config.ingest.rename_fields ? 1 : 0.5 }}>
              <Form.Field>
                <label>Mappings</label>
              </Form.Field>

              {/* <Form.Group widths="equal">
              <Form.Select label="Source" options={[]} disabled />
              <Form.Input label="Destination" />
            </Form.Group> */}

              {formdata.config.ingest.field_mappings.map((field, i) => (
                <Form.Group key={field.src + i} widths="equal">
                  <Form.Select
                    fluid
                    placeholder={
                      !formdata.config.extract.table_id &&
                      (formdata.config.extract.dataset_ids === null || formdata.config.extract.dataset_ids.length === 0)
                        ? 'No dataset selected'
                        : undefined
                    }
                    options={SelectedTableColumns.filter(
                      c =>
                        c.value === field.src ||
                        !formdata.config.ingest.field_mappings.map(fm => fm.src).includes(c.value as string)
                    )}
                    loading={tableColumnsLoading || datasetsLoading}
                    value={field.src || ''}
                    name={`config.ingest.field_mappings[${i}].src`}
                    onChange={onChange}
                  />

                  <Form.Input
                    fluid
                    value={field.dst || ''}
                    name={`config.ingest.field_mappings[${i}].dst`}
                    onChange={onChange}
                  />

                  <Button
                    type="button"
                    color="red"
                    icon
                    onClick={() => {
                      onChange(null, {
                        name: 'config.ingest.field_mappings',
                        value: formdata.config.ingest.field_mappings.filter((_, j) => i !== j),
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Group>
              ))}

              <Button
                disabled
                type="button"
                compact
                size="mini"
                color="blue"
                onClick={() => {
                  onChange(null, {
                    name: 'config.ingest.field_mappings',
                    value: [...formdata.config.ingest.field_mappings, { src: '', dst: '' }],
                  });
                }}
              >
                <Icon name="plus" /> Add Mapping
              </Button>
            </div>

            <Divider />

            <Form.Select
              label="Feed"
              options={
                feeds?.data.map(f => ({
                  key: f.id,
                  value: f.id,
                  text: f.name,
                })) || []
              }
              loading={feedsLoading}
              value={formdata.config.ingest.feed_id || ''}
              name="config.ingest.feed_id"
              onChange={onChange}
              error={errors.ingestFeedId}
            />
          </div>
        </div>
      </Layout>
    </Form>
  );
};

export default EditPipelineForm;
