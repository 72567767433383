import { format, parse } from 'date-fns';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Form, Loader, Table } from 'semantic-ui-react';

import { ReportingStatResponseAttributes } from 'src/api/accounts';
import { AdminGetAccountsReportingParams } from 'src/api/admin/account-reporting';
import { useListReportingStatsQuery } from 'src/api/admin/accounts';
import Breadcrumbs from 'src/components/Breadcrumbs';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';
import theme from 'src/styles/theme';
import { ReportingTimeRange, ReportingTimeRanges, TimezoneOptions } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';

export const STATUS_FILTERS = [
  { key: 'enabled', value: true, text: 'Enabled' },
  { key: 'disabled', value: false, text: 'Disabled' },
];

const getInitialStatsFilters = (query: URLSearchParams): AdminGetAccountsReportingParams => {
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const timeRange = query.get('timeRange');
  let enabled = undefined;
  switch (query.get('enabled')) {
    case '1':
      enabled = true;
      break;
    case '0':
      enabled = false;
      break;
  }

  return {
    timeRange: timeRange ? (timeRange as ReportingTimeRange) : ('today' as ReportingTimeRange),
    startDate: startDate ? startDate : format(new Date(), 'yyyy-MM-dd'),
    endDate: endDate ? endDate : format(new Date(), 'yyyy-MM-dd'),
    enabled,
    timezone: query.get('timezone') || 'UTC',
  };
};

const AdminReportingV2 = () => {
  const { push, replace } = useHistory();
  const query = useSearchQuery();
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([null, null]);
  const [statsFilters, setStatsFilters] = useState<AdminGetAccountsReportingParams>(() => {
    const r = getInitialStatsFilters(query);
    setDaterange([parse(r.startDate, 'yyyy-MM-dd', new Date()), parse(r.endDate, 'yyyy-MM-dd', new Date())]);
    return r;
  });

  const [direction, setDirection] = useState<'ascending' | 'descending' | undefined>('descending');
  const [column, setColumn] = useState<typeof ReportingStatResponseAttributes[number]>('dials');

  const { data, isLoading } = useListReportingStatsQuery({
    startDate: statsFilters.startDate,
    endDate: statsFilters.endDate,
    enabled: statsFilters?.enabled,
    timezone: statsFilters?.timezone,
  });

  const totals = useMemo(() => {
    const intObj = {
      dials: 0,
      connects: 0,
      inbounds: 0,
      transfers: 0,
      humans: 0,
      xferToCallcenter: 0,
    };
    if (!data) return intObj;
    return data?.reduce((acc, stat) => {
      acc.dials += stat.dials;
      acc.connects += stat.connects;
      acc.inbounds += stat.inbounds;
      acc.transfers += stat.transfers;
      acc.humans += stat.humans;
      acc.xferToCallcenter += stat.xfer_to_callcenter;
      return acc;
    }, intObj);
  }, [data]);

  useEffect(() => {
    if (!query.has('enabled')) {
      query.set('enabled', '1');
      replace({ search: query.toString() });
    }
    const today = format(new Date(), 'yyyy-MM-dd');
    if (!query.has('startDate')) {
      query.set('startDate', today);
      replace({ search: query.toString() });
    }
    if (!query.has('endDate')) {
      query.set('endDate', today);
      replace({ search: query.toString() });
    }
    if (!query.has('direction')) {
      query.set('direction', 'descending');
      replace({ search: query.toString() });
    }
    if (!query.has('column')) {
      query.set('column', 'dials');
      replace({ search: query.toString() });
    }
    if (!query.has('timeRange')) {
      query.set('timeRange', 'today');
      replace({ search: query.toString() });
    }
    if (!query.has('timezone')) {
      query.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      replace({ search: query.toString() });
    }
  });

  const updateSort = (col: typeof ReportingStatResponseAttributes[number]) => () => {
    const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'descending';
    setDirection(newDirection);
    setColumn(col);
    // update query params
    query.set('column', col);
    query.set('direction', newDirection);
    replace({ search: query.toString() });
  };

  const onChangeTimeRange = useCallback(
    (d, { value }) => {
      let startDate = format(d[0] || new Date(), 'yyyy-MM-dd');
      let endDate = format(d[1] || new Date(), 'yyyy-MM-dd');
      const timeRange = value;
      if (value !== 'custom') {
        [startDate, endDate] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);
      }
      // update query params
      query.set('startDate', startDate);
      query.set('endDate', endDate);
      query.set('timeRange', timeRange);
      replace({ search: query.toString() });
    },
    [query, replace]
  );

  const onChangeDates = useCallback(
    (d: [Date | null, Date | null]) => {
      setDaterange(d);
      const [startDate, endDate] = d;
      if (startDate !== null && endDate !== null) {
        setStatsFilters(prev => ({
          ...prev,
          timeRange: 'custom' as ReportingTimeRange,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
        }));
        onChangeTimeRange(d, { value: 'custom' });
      }
    },
    [onChangeTimeRange]
  );

  useEffect(() => {
    setStatsFilters(() => {
      const r = getInitialStatsFilters(query);
      setDaterange([parse(r.startDate, 'yyyy-MM-dd', new Date()), parse(r.endDate, 'yyyy-MM-dd', new Date())]);
      return r;
    });
  }, [query]);

  return (
    <Container>
      <Helmet>
        <title>Reporting v2 - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Reporting', 'v2']} />
        </Header>
      </Row>

      <Form>
        <Form.Group>
          <Form.Select
            label="Status"
            placeholder="All"
            clearable
            options={STATUS_FILTERS}
            value={typeof statsFilters.enabled === 'undefined' ? '' : statsFilters.enabled}
            onChange={(_, { value }) => {
              let enabled = '';
              switch (value) {
                case '':
                  enabled = '';
                  break;
                default:
                  enabled = Number(value).toString();
                  break;
              }

              query.set('enabled', enabled);
              push({ search: query.toString() });
              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />
          <Form.Field>
            <label>Date Range</label>
            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.map(timeRange => ({
                key: timeRange,
                text: capitalize(timeRange),
                value: timeRange,
              }))}
              value={statsFilters.timeRange}
            />
          </Form.Field>
          <Form.Field>
            <div style={{ width: 240 }}>
              <label style={{ fontSize: 13, fontWeight: 700, lineHeight: 2 }}>Custom Range</label>
              <DatePicker
                selectsRange
                startDate={daterange[0]}
                endDate={daterange[1]}
                customInput={<Form.Input fluid icon="calendar" iconPosition="left" />}
                monthsShown={2}
                showPopperArrow={false}
                onChange={onChangeDates}
              />
            </div>
          </Form.Field>
          <Form.Field>
            <Form.Select
              label="Timezone"
              options={TimezoneOptions}
              value={statsFilters.timezone}
              onChange={(_, { value }) => {
                query.set('timezone', value?.toString() || 'UTC');
                push({ search: query.toString() });
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <Table compact="very" striped sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'account_id' ? direction : undefined}
              onClick={updateSort('account_id')}
            >
              ID
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'account_name' ? direction : undefined}
              onClick={updateSort('account_name')}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'dials' ? direction : undefined} onClick={updateSort('dials')}>
              Dials
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'connects' ? direction : undefined} onClick={updateSort('connects')}>
              Connects
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'connect_pct' ? direction : undefined}
              onClick={updateSort('connect_pct')}
            >
              Connects %
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'inbounds' ? direction : undefined} onClick={updateSort('inbounds')}>
              Inbounds
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'transfers' ? direction : undefined} onClick={updateSort('transfers')}>
              Transfers
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'transfer_pct' ? direction : undefined}
              onClick={updateSort('transfer_pct')}
            >
              Transfers %
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'humans' ? direction : undefined} onClick={updateSort('humans')}>
              Humans
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'xfer_to_callcenter' ? direction : undefined}
              onClick={updateSort('xfer_to_callcenter')}
            >
              Xfer to CallCenter
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'xfer_to_callcenter_pct' ? direction : undefined}
              onClick={updateSort('xfer_to_callcenter_pct')}
            >
              Transfer %
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isLoading && (
            <Table.Row>
              <Table.Cell colSpan={12} style={{ textAlign: 'center', padding: 40 }}>
                <Loader inline active />
              </Table.Cell>
            </Table.Row>
          )}
          {data?.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={12} style={{ textAlign: 'center', padding: 40 }}>
                No data found
              </Table.Cell>
            </Table.Row>
          )}
          {data
            ?.sort((a, b) => {
              if (typeof a[column] === 'string') {
                return direction === 'ascending'
                  ? a[column].toString().localeCompare(b[column].toString())
                  : b[column].toString().localeCompare(a[column].toString());
              }
              return direction === 'ascending'
                ? Number(a[column]) - Number(b[column])
                : Number(b[column]) - Number(a[column]);
            })
            .map(stat => (
              <Table.Row key={stat.account_id}>
                <Table.Cell>
                  <div className="ui label">{stat.account_id}</div>
                </Table.Cell>
                <Table.Cell>
                  <Row style={{ flexDirection: 'column' }}>
                    <strong>{stat.account_name}</strong>
                    <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
                      {stat?.created_by_email}
                    </span>
                  </Row>
                </Table.Cell>
                <Table.Cell>{stat.dials.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.connects.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.connect_pct}%</Table.Cell>
                <Table.Cell>{stat.inbounds.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.transfers.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.transfer_pct}%</Table.Cell>
                <Table.Cell>{stat.humans.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.xfer_to_callcenter.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.xfer_to_callcenter_pct}%</Table.Cell>
                <Table.Cell>
                  <Button
                    icon="eye"
                    onClick={() =>
                      push(
                        `/admin/reporting/v2/over-time?id=${stat.account_id}&startDate=${query.get(
                          'startDate'
                        )}&endDate=${query.get('endDate')}&timeRange=${query.get('timeRange')}&timezone=${query.get(
                          'timezone'
                        )}`
                      )
                    }
                    title="View Detail Report"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <b>Totals:</b>
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.dials.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.connects.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.dials > 0 ? ((totals.connects / totals.dials) * 100).toFixed(2) : 100}%</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.inbounds.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.transfers.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.transfers > 0 ? ((totals.inbounds / totals.transfers) * 100).toFixed(2) : 100}%</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.humans.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.xferToCallcenter.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.humans > 0 ? ((totals.xferToCallcenter / totals.humans) * 100).toFixed(2) : 100}%</b>
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
};

export default AdminReportingV2;
