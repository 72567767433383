import { Icon } from 'semantic-ui-react';

import { useSaveVoiceRecordingMutation } from 'src/api/voice-recordings';
import { VoiceRecording } from 'src/types';

type Props = {
  recording: VoiceRecording;
};

const VoiceCallRecordingStar = ({ recording }: Props) => {
  const { mutateAsync, isLoading } = useSaveVoiceRecordingMutation();

  const toggleFavorite = async (): Promise<void> => {
    recording.favorite = !recording.favorite;
    await mutateAsync(recording);
  };

  const iconName = recording.favorite ? 'star' : 'star outline';
  const color = recording.favorite ? 'yellow' : 'grey';
  return (
    <Icon name={iconName} color={color} style={{ cursor: 'pointer' }} onClick={toggleFavorite} loading={isLoading} />
  );
};

export default VoiceCallRecordingStar;
