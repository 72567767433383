import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { CheckboxProps, Form, Icon, Input } from 'semantic-ui-react';

import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import { VoiceRecordingType } from 'src/types';
import ListVoiceCallRecordingsBody from './ListVoiceCallRecordingsBody';

const VoiceCallRecordingsList = () => {
  const [recType, setRecType] = useState<VoiceRecordingType>(VoiceRecordingType.all);
  const [search, setSearch] = useState('');
  const [phone, setPhone] = useState('');
  const [includeArchived, setIncludeArchived] = useState(false);
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  return (
    <Container>
      <Helmet>
        <title>Voice Recordings | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <PageHeader style={{ margin: 0 }}>
          <span style={{ color: theme.gray }}>Voice</span> <span style={{ padding: '0 0.5rem' }}>/</span> Recordings
        </PageHeader>

        <Form onSubmit={() => setPhone(search)}>
          <Row style={{ alignItems: 'center', gap: '0.5rem' }}>
            <Icon name="filter" />

            <Form.Field style={{ marginBottom: 0 }}>
              <Input
                value={search}
                onChange={(_, { value }) => setSearch(value)}
                placeholder="Phone Number"
                icon="phone"
                iconPosition="left"
                style={{ marginBottom: 0 }}
              />
            </Form.Field>

            <Form.Field style={{ marginBottom: 0 }}>
              <Form.Select
                value={recType}
                onChange={(_, { value }) => setRecType(value as VoiceRecordingType)}
                options={[
                  { value: VoiceRecordingType.all, text: 'All Types' },
                  { value: VoiceRecordingType.call, text: 'Calls' },
                  { value: VoiceRecordingType.voicemail, text: 'Voicemails' },
                ]}
              />
            </Form.Field>

            <Form.Field style={{ marginBottom: 0 }}>
              <Form.Checkbox
                checked={includeArchived}
                label="Include Archived"
                onChange={(_e: React.FormEvent<HTMLInputElement>, { checked, name }: CheckboxProps) =>
                  setIncludeArchived(checked || false)
                }
              />
            </Form.Field>

            <Form.Checkbox
              checked={onlyFavorites}
              label="Only Favorites"
              onChange={(_e: React.FormEvent<HTMLInputElement>, { checked, name }: CheckboxProps) =>
                setOnlyFavorites(checked || false)
              }
            />
          </Row>
        </Form>
      </Row>

      <PaginatedTable
        headers={[
          '',
          'Recording SID',
          'Created At',
          'Phone Number',
          'Type',
          'Direction',
          'Duration',
          'Listened At',
          'Recording',
          '',
        ]}
        renderBody={props => (
          <ListVoiceCallRecordingsBody
            {...props}
            type={recType}
            phone={phone}
            includeArchived={includeArchived}
            onlyFavorites={onlyFavorites}
          />
        )}
      />
    </Container>
  );
};

export default VoiceCallRecordingsList;
