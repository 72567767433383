import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Loader, Table } from 'semantic-ui-react';

import { useListVoiceConfigsQuery } from 'src/api/admin/voice';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import AdminAccountBillingVoiceConfigForm from './AdminAccountBillingVoiceConfigForm';

type Props = {
  defaultRate?: number;
};

const AdminAccountBillingVoiceConfigsListBody = ({
  limit,
  currentPage,
  defaultRate,
  setPageCount,
}: RenderProps & Props) => {
  const { id } = useParams<{ id: string }>();
  const { isLoading: voiceConfigsLoading, data: voiceConfigs } = useListVoiceConfigsQuery({
    limit,
    offset: (currentPage - 1) * limit,
    accountID: Number(id),
  });

  useEffect(() => {
    if (!voiceConfigs?.total) return;
    setPageCount(Math.ceil(voiceConfigs.total / limit));
  }, [setPageCount, voiceConfigs?.total, limit]);

  if (voiceConfigsLoading) {
    return (
      <Table.Row>
        <Table.Cell colSpan={3}>
          <Loader inline active />
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {voiceConfigs?.data?.map(config => (
        <AdminAccountBillingVoiceConfigForm key={config.id} config={config} defaultRate={defaultRate} />
      ))}
    </>
  );
};

const AdminAccountBillingVoiceConfigs = ({ defaultRate }: Props) => {
  return (
    <Grid.Column width={16}>
      <PaginatedTable
        qsParam={false}
        headers={['Voice Config', 'Billing Rate (Per Connect)']}
        renderBody={props => <AdminAccountBillingVoiceConfigsListBody {...props} defaultRate={defaultRate} />}
      />
    </Grid.Column>
  );
};

export default AdminAccountBillingVoiceConfigs;
