import { Label, Loader, Table } from 'semantic-ui-react';

import { AdminGetAccountsReportingParams, useAdminGetAccountsReportingQuery } from 'src/api/admin/account-reporting';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { Account } from 'src/types';

type Props = {
  account: Account;
  filters: AdminGetAccountsReportingParams;
};

const AdminAccountsReportingRow = ({ account: a, filters }: Props) => {
  const { data, error, isLoading } = useAdminGetAccountsReportingQuery(a.id, {
    timeRange: filters.timeRange,
    startDate: filters.startDate,
    endDate: filters.endDate,
  });

  if (isLoading) {
    return (
      <Table.Row key={a.id}>
        <Table.Cell collapsing>
          <Label>{a.id}</Label>
        </Table.Cell>
        <Table.Cell>
          <Row style={{ flexDirection: 'column' }}>
            <strong>{a.name}</strong>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
              {a.created_by?.email}
            </span>
          </Row>
        </Table.Cell>
        <Table.Cell colSpan={10}>
          <Loader inline active />
        </Table.Cell>
      </Table.Row>
    );
  }

  if (error) {
    return (
      <Table.Row key={a.id}>
        <Table.Cell collapsing>
          <Label>{a.id}</Label>
        </Table.Cell>
        <Table.Cell>
          <Row style={{ flexDirection: 'column' }}>
            <strong>{a.name}</strong>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
              {a.created_by?.email}
            </span>
          </Row>
        </Table.Cell>
        <Table.Cell colSpan={10}>
          <Label color="red">Unable to load stats</Label>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table.Row key={a.id}>
      <Table.Cell collapsing>
        <Label>{a.id}</Label>
      </Table.Cell>
      <Table.Cell>
        <Row style={{ flexDirection: 'column' }}>
          <strong>{a.name}</strong>
          <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
            {a.created_by?.email}
          </span>
        </Row>
      </Table.Cell>
      <Table.Cell>{data?.stats?.dials.toLocaleString()}</Table.Cell>
      <Table.Cell>{data?.stats?.connects.toLocaleString()}</Table.Cell>
      <Table.Cell>{data?.stats?.connectPct.toFixed(2)}%</Table.Cell>
      <Table.Cell>{data?.stats?.inbounds.toLocaleString()}</Table.Cell>
      <Table.Cell>{data?.stats?.transfers.toLocaleString()}</Table.Cell>
      <Table.Cell>{data?.stats?.transfersPct.toFixed(2)}%</Table.Cell>
      <Table.Cell>{data?.stats?.humans.toLocaleString()}</Table.Cell>
      <Table.Cell>{data?.stats?.xferToCall.toLocaleString()}</Table.Cell>
      <Table.Cell>{data?.stats?.xferToCallPct.toFixed(2)}%</Table.Cell>
      <Table.Cell>{data?.stats?.avgTransferTime.toFixed(1)}</Table.Cell>
    </Table.Row>
  );
};

export default AdminAccountsReportingRow;
