import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Dataset, DatasetField, DatasetList, Delimiter, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type DatasetResponse = ApiResponse & {
  dataset?: Dataset;
};

export type DatasetsResponse = ApiResponse & {
  datasets: PaginatedResponse<Dataset>;
};

export type ListDatasetsParams = {
  limit: number;
  offset: number;
};

export type DatasetListsResponse = ApiResponse & {
  lists: PaginatedResponse<DatasetList>;
};

export const useListDatasetsQuery = (params: ListDatasetsParams) => {
  return useQuery(
    ['datasets', params],
    async () => {
      const res = await http.get<DatasetsResponse>('/api/datasets-v2', { params });
      return res.data.datasets;
    },
    { refetchInterval: 10e3 }
  );
};

export const useGetDatasetQuery = (id: string) => {
  return useQuery(['datasets', id], async () => {
    if (!id) return;
    const res = await http.get<DatasetResponse>(`/api/datasets-v2/${id}`);
    return res.data.dataset;
  });
};

export type DatasetRowCountResponse = ApiResponse & {
  final?: number;
  dialable?: number;
  total?: number;
  blacklisted_global?: number;
  blacklisted_federal?: number;
  blacklisted_client?: number;
  connects?: number;
  carriers?: number;
  leadscores?: number;
  rejected?: number;
};

export const useGetDatasetRowCountQuery = (id: string, filters?: DatasetStatsFilters) => {
  return useQuery(
    ['datasets', id, 'row-count'],
    async () => {
      if (!id) return;
      const res = await http.get<DatasetRowCountResponse>(`/api/datasets-v2/${id}/row-count`);
      return res.data;
    },
    { refetchInterval: 30e3 }
  );
};

export type DatasetStatsResponse = ApiResponse & {
  range: Record<string, number>;
  errorsCodes: Record<string, number>;
  statuses: Record<string, number>;
};

export const useGetDatasetStatsQuery = ({ datasetId, ...params }: { datasetId: string } & DatasetStatsFilters) => {
  return useQuery(['datasets', datasetId, 'stats', params], async () => {
    if (!datasetId) return;
    const res = await http.get<DatasetStatsResponse>(`/api/datasets-v2/${datasetId}/stats`, {
      params: {
        start: params.dateRange.start?.toISOString() ?? '',
        end: params.dateRange.end?.toISOString() ?? '',
      },
    });
    return res.data;
  });
};

export type AddDatasetParams = {
  name: string;
};

export const useAddDatasetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddDatasetParams) => {
      const res = await http.post<DatasetResponse>(`/api/datasets-v2`, params);
      return res.data.dataset;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasets']);
      },
    }
  );
};

export type SaveDatasetParams = {
  dataset: Dataset;
};

export const useSaveDatasetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: SaveDatasetParams) => {
      const res = await http.post<DatasetResponse>(`/api/datasets-v2/${params.dataset.id}`, params.dataset);
      return res.data.dataset;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasets']);
      },
    }
  );
};

export const useDeleteDatasetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/datasets-v2/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['datasets']);
      },
    }
  );
};

export type AddDatasetFieldParams = {
  datasetId: string;
  field: DatasetField;
};

export const useAddDatasetFieldMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddDatasetFieldParams) => {
      const res = await http.post<DatasetResponse>(`/api/datasets-v2/${params.datasetId}/custom-fields`, params.field);
      return res.data.dataset;
    },
    {
      onSuccess: (_, { datasetId }) => {
        queryClient.invalidateQueries(['datasets', datasetId]);
      },
    }
  );
};

export type RemoveDatasetFieldParams = {
  datasetId: string;
  fieldId: string;
};

export const useRemoveDatasetFieldMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: RemoveDatasetFieldParams) => {
      const res = await http.delete<DatasetResponse>(
        `/api/datasets-v2/${params.datasetId}/custom-fields/${params.fieldId}`
      );
      return res.data;
    },
    {
      onSuccess: (_, { datasetId }) => {
        queryClient.invalidateQueries(['datasets', datasetId]);
      },
    }
  );
};

export type DatasetUploadFormdata = {
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
  hasHeader: boolean;
  listId: string;
};

export type DatasetUploadParams = DatasetUploadFormdata & {
  id: string;
};

export type DatasetUploadResponse = ApiResponse & {
  tmpRawPath: string;
};

export type DatasetListCreateParams = {
  datasetId: string;
  name: string;
};

export const useDatasetUploadMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: DatasetUploadParams) => {
      if (!params.file) {
        const err = {
          response: {
            status: 422,
            statusText: 'Unprocessable Entity',
            data: { success: false, errors: [{ error: 'file is required' }] },
          },
        };
        throw err;
      }

      const fd = new FormData();
      fd.append('file', params.file);
      fd.append('delimiter', params.delimiter);
      fd.append('field_mappings', params.fieldMappings.join(','));
      fd.append('has_header', params.hasHeader ? '1' : '0');
      fd.append('list_id', params.listId);

      const res = await http.post<DatasetUploadResponse>(`/api/datasets-v2/${params.id}/upload`, fd);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['jobs']);
      },
    }
  );
};

export type ListDatasetListsParams = {
  datasetId: string;
  limit: number;
  offset: number;
};

export type ListDatasetsListsParams = {
  datasetsIds: string[];
  limit: number;
  offset: number;
};

export const useListDatasetsListsQuery = (params: ListDatasetsListsParams) => {
  return useQuery(['dataset-lists', params], async () => {
    const res = await http.get<DatasetListsResponse>(`/api/datasets-v2/multiple-lists`, { params });
    return res.data.lists;
  });
};

export const useListDatasetListsQuery = ({ datasetId, ...params }: ListDatasetListsParams) => {
  return useQuery(['datasets', datasetId, 'lists'], async () => {
    if (!datasetId) return undefined;
    const res = await http.get<DatasetListsResponse>(`/api/datasets-v2/${datasetId}/lists`, { params });
    return res.data.lists;
  });
};

export const useListAllDatasetListsQuery = () => {
  return useQuery(['dataset-lists'], async () => {
    const res = await http.get<DatasetListsResponse>(`/api/datasets-v2/lists`);
    return res.data.lists;
  });
};

export type DatasetListRowCountParams = {
  datasetId: string;
  listId: string;
};

export type DatasetListRowCountResponse = ApiResponse & {
  count: number;
};

export type DatasetListResponse = ApiResponse & {
  list?: DatasetList;
};

export const useDatasetListRowCountQuery = (params: DatasetListRowCountParams) => {
  return useQuery(['datasets', params.datasetId, 'lists', params.listId, 'row-count'], async () => {
    const res = await http.get<DatasetListRowCountResponse>(
      `/api/datasets-v2/${params.datasetId}/lists/${params.listId}/row-count`
    );
    return res.data;
  });
};

export const useDatasetListCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: DatasetListCreateParams) => {
      const res = await http.post<DatasetListResponse>(`/api/datasets-v2/${params.datasetId}/lists`, {
        name: params.name,
      });
      return res.data;
    },
    {
      onSuccess: (_, { datasetId }) => {
        queryClient.invalidateQueries(['datasets', datasetId, 'lists']);
      },
    }
  );
};

export type DatasetListDeleteParams = {
  datasetId: string;
  listId: string;
};

export const useDatasetListDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: DatasetListDeleteParams) => {
      const res = await http.delete<Response>(`/api/datasets-v2/${params.datasetId}/lists/${params.listId}`);
      return res.data;
    },
    {
      onSuccess: (_, { datasetId }) => {
        queryClient.invalidateQueries(['datasets', datasetId, 'lists']);
      },
    }
  );
};

export type DatasetStatsFilters = {
  dateRange: {
    start: Date | null;
    end: Date | null;
  };
};

export type DatasetStatsParams = {
  datasetId: string;
};
