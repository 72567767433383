import { useRef } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { useSaveVoiceRecordingMutation } from 'src/api/voice-recordings';
import { VoiceRecording } from 'src/types';

const VoiceCallRecordingActions = ({ recording }: { recording: VoiceRecording }) => {
  const modalRef = useRef(null);
  // const [error, setError] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useSaveVoiceRecordingMutation();

  const onMarkUnheard = async (): Promise<void> => {
    // setError(undefined);

    try {
      recording.listened_at = null;
      await mutateAsync(recording);

      if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
        // @ts-ignore
        modalRef.current.handleClose();
      }
    } catch (e: any) {
      // apiErrorHandler(e, setError);
    }
  };

  const toggleArchive = async (): Promise<void> => {
    // setError(undefined);

    try {
      recording.archived = !recording.archived;
      await mutateAsync(recording);

      if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
        // @ts-ignore
        modalRef.current.handleClose();
      }
    } catch (e: any) {
      // apiErrorHandler(e, setError);
    }
  };

  const { REACT_APP_BETA_URL: betaURL } = process.env;
  const downloadUrl = `${betaURL}/api/voice/recordings/${recording.id}/download`;

  return (
    <Dropdown button text="Actions">
      <Dropdown.Menu>
        <Dropdown.Item key="download" text="Download" href={downloadUrl} />
        <Dropdown.Item
          key="archive"
          text={recording.archived ? 'Unarchive' : 'Archive'}
          onClick={toggleArchive}
          loading={isLoading}
        />
        <Dropdown.Item key="markUnheard" text="Mark as Unheard" loading={isLoading} onClick={onMarkUnheard} />
      </Dropdown.Menu>
    </Dropdown>
    // <Modal
    //   size="tiny"
    //   ref={modalRef}
    //   trigger={
    //     <Button icon title="More Actions">
    //       <Icon name="ellipsis horizontal" />
    //     </Button>
    //   }
    //   onClose={onCloseModal}
    // >
    //   <Modal.Header>Voice Recording Actions</Modal.Header>
    //   <Modal.Content>
    //     <div style={{ textAlign: 'center' }}>
    //       <ApiMessage data={error} />
    //       <Button type="button" href={downloadUrl}>
    //         Download
    //       </Button>
    //       <Button type="button" loading={isLoading} onClick={toggleArchive}>
    //         {recording.archived ? 'Unarchive' : 'Archive'}
    //       </Button>
    //       <Button type="button" loading={isLoading} onClick={onMarkUnheard}>
    //         Mark as Unheard
    //       </Button>
    //     </div>
    //   </Modal.Content>
    // </Modal>
  );
};

export default VoiceCallRecordingActions;
