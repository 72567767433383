// import { useCallback, useState } from 'react';
import { format, parse } from 'date-fns';
import { LocationDescriptor } from 'history';
import { capitalize } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Icon, Loader, Table } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { AgentAttributionDataFilters, useGetAgentAttributionDataQuery } from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import useSearchQuery from 'src/hooks/useSearchQuery';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import { ReportingTimeRange, ReportingTimeRanges } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';

const { REACT_APP_BETA_URL: betaURL } = process.env;

const ReportingAgentAttributionColumns = ['label', 'calls', 'sales', 'total', 'conv', 'avg'] as const;

type ReportingAgentAttributionColumn = typeof ReportingAgentAttributionColumns[number];

const groupLabel = (view: string) => {
  switch (view) {
    case 'agent':
      return 'Agent Name';
    case 'campaign':
      return 'Campaign Name';
    case 'created':
      return 'Created Date';
    default:
      return 'Row Labels';
  }
};

const href = (params: {
  startDate: string;
  endDate: string;
  byCreatedDate: boolean;
  agent: string;
  campaign: string;
  created: string;
  sales: boolean;
}): LocationDescriptor => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value.toString());
  }

  return {
    pathname: `/reporting/agent-attribution/details`,
    search: searchParams.toString(),
  };
};

const ReportingAgentAttribution = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();
  const [filters, setFilters] = useState<AgentAttributionDataFilters & { timeRange: ReportingTimeRange }>({
    view: 'agent',
    byCreatedDate: false,
    timeRange: 'this year',
    startDate: format(new Date().setMonth(0, 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([
    new Date(new Date().setMonth(0, 1)),
    new Date(),
  ]);
  const { replace } = useHistory();
  const query = useSearchQuery();
  const [column, setColumn] = useState<ReportingAgentAttributionColumn>(
    (query.get('column') ?? 'label') as ReportingAgentAttributionColumn
  );
  const [direction, setDirection] = useState<'ascending' | 'descending'>(
    (query.get('direction') ?? 'ascending') as 'ascending' | 'descending'
  );
  // const [summary, setSummary] = useState<AgentAttributionData>();
  const { data: AgentAttributionData, isLoading: isDataLoading } = useGetAgentAttributionDataQuery(filters);

  const onChangeTimeRange = useCallback((_, { value }) => {
    if (value === 'custom') {
      setFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange }));
    } else {
      const [startDate, endDate] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);

      setDaterange([parse(startDate, 'yyyy-MM-dd', new Date()), parse(endDate, 'yyyy-MM-dd', new Date())]);

      setFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange, startDate, endDate }));
    }
  }, []);

  const onChangeDates = useCallback((d: [Date | null, Date | null]) => {
    setDaterange(d);
    const [startDate, endDate] = d;
    if (startDate !== null && endDate !== null) {
      setFilters(prev => ({
        ...prev,
        timeRange: 'custom' as ReportingTimeRange,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      }));
    }
  }, []);

  const onChangeView = useCallback((_, { value }) => {
    setFilters(prev => ({ ...prev, view: value }));
  }, []);

  const onDateColumn = useCallback((_, { value }) => {
    setFilters(prev => ({ ...prev, byCreatedDate: value }));
  }, []);

  const updateSort = useCallback(
    (col: ReportingAgentAttributionColumn) => () => {
      const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setDirection(newDirection);
      setColumn(col);

      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [column, direction, query, replace]
  );

  const data = useMemo(() => {
    return AgentAttributionData?.data || [];
  }, [AgentAttributionData?.data]);

  const summary = useMemo(() => {
    const calls = data.reduce((sum, item) => sum + item.calls, 0);
    const sales = data.reduce((sum, item) => sum + item.sales, 0);
    const total = data.reduce((sum, item) => sum + item.total, 0);

    return {
      calls,
      sales,
      total,
      conv: calls ? sales / calls : 0,
      avg: sales ? total / sales : 0,
    };
  }, [data]);

  const sortedData = useMemo(
    () =>
      data.sort((a, b) => {
        if (!column) return 1;
        if (direction === 'ascending') {
          return a[column] > b[column] ? 1 : -1;
        } else {
          return a[column] < b[column] ? 1 : -1;
        }
      }),
    [data, column, direction]
  );

  const DOWNLOAD_URL = useMemo(() => {
    const url = new URL(`${betaURL}/api/reporting/agent-attribution/download`);

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(filters)) {
      searchParams.append(key, value.toString());
    }

    url.search = searchParams.toString();

    return url.toString();
  }, [filters]);

  if (isLoading) return <Loading />;

  if (!user || !user.active_account) return <NotFound />;

  return (
    <Container>
      <Helmet>
        <title>Agent Attribution - Reporting | datascore</title>
      </Helmet>

      <PageHeader>
        <Breadcrumbs crumbs={['Reporting', 'Agent Attribution']} />
      </PageHeader>

      <Form>
        <Form.Group>
          <Form.Select
            label="Date Column"
            onChange={onDateColumn}
            options={[
              { key: 'call_date', text: 'Call Date', value: false },
              { key: 'created_date', text: 'Created Date', value: true },
            ]}
            value={filters.byCreatedDate}
          />

          <Form.Select
            label="Group By"
            onChange={onChangeView}
            options={[
              { key: 'agent', text: 'Agent', value: 'agent' },
              { key: 'campaign', text: 'Campaign', value: 'campaign' },
              { key: 'created', text: 'Created Date', value: 'created' },
              // { key: 'called', text: 'Called View', value: 'called' },
            ]}
            value={filters.view}
          />

          <Form.Field style={{ marginBottom: 0 }}>
            <label style={{ width: 230 }}>
              Time Range{' '}
              <span style={{ opacity: 0.4, fontSize: '0.9em', whiteSpace: 'nowrap' }}>
                ({filters.startDate} - {filters.endDate})
              </span>
            </label>

            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.map(timeRange => ({
                key: timeRange,
                text: timeRange === 'this year' ? 'YTD' : capitalize(timeRange),
                value: timeRange,
              }))}
              value={filters.timeRange}
            />
          </Form.Field>

          <Form.Field>
            <div style={{ width: 240 }}>
              <label style={{ fontSize: 13, fontWeight: 700, lineHeight: 2 }}>Custom Range</label>
              <DatePicker
                selectsRange
                startDate={daterange[0]}
                endDate={daterange[1]}
                customInput={<Form.Input fluid icon="calendar" iconPosition="left" />}
                monthsShown={2}
                showPopperArrow={false}
                onChange={onChangeDates}
              />
            </div>
          </Form.Field>

          {sortedData.length > 0 && (
            <Form.Field>
              <label style={{ fontSize: 13, fontWeight: 700, lineHeight: 1.8 }}>&nbsp;</label>
              <Button type="button" as="a" href={DOWNLOAD_URL} basic>
                <Icon name="download" /> Export CSV
              </Button>
            </Form.Field>
          )}
        </Form.Group>
      </Form>

      <div style={{ overflowX: 'auto' }}>
        <Table compact collapsing celled selectable sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={(column === 'label' && direction) || undefined} onClick={updateSort('label')}>
                {groupLabel(filters.view)}
              </Table.HeaderCell>

              <Table.HeaderCell
                textAlign="right"
                sorted={(column === 'calls' && direction) || undefined}
                onClick={updateSort('calls')}
              >
                Calls
              </Table.HeaderCell>

              <Table.HeaderCell
                textAlign="right"
                sorted={(column === 'sales' && direction) || undefined}
                onClick={updateSort('sales')}
              >
                Sales
              </Table.HeaderCell>

              <Table.HeaderCell
                textAlign="right"
                sorted={(column === 'total' && direction) || undefined}
                onClick={updateSort('total')}
              >
                Total $
              </Table.HeaderCell>

              <Table.HeaderCell
                textAlign="right"
                sorted={(column === 'conv' && direction) || undefined}
                onClick={updateSort('conv')}
              >
                Conv %
              </Table.HeaderCell>

              <Table.HeaderCell
                textAlign="right"
                sorted={(column === 'avg' && direction) || undefined}
                onClick={updateSort('avg')}
              >
                Avg $
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isDataLoading ? (
              <Table.Row style={{ position: 'relative' }}>
                <Table.Cell colSpan={6} textAlign="center">
                  <Loader active inline />
                </Table.Cell>
              </Table.Row>
            ) : (
              sortedData.map(raw => (
                <Table.Row key={raw.label}>
                  <Table.Cell>{raw.label}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Link
                      to={href({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        byCreatedDate: filters.byCreatedDate,
                        sales: false,
                        agent: filters.view === 'agent' ? raw.label : '',
                        campaign: filters.view === 'campaign' ? raw.label : '',
                        created: filters.view === 'created' ? raw.label : '',
                      })}
                    >
                      {raw.calls}
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Link
                      to={href({
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                        byCreatedDate: filters.byCreatedDate,
                        sales: true,
                        agent: filters.view === 'agent' ? raw.label : '',
                        campaign: filters.view === 'campaign' ? raw.label : '',
                        created: filters.view === 'created' ? raw.label : '',
                      })}
                    >
                      {raw.sales}
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    ${raw.total.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  </Table.Cell>
                  <Table.Cell textAlign="right">{(raw.conv * 100).toFixed(1)}%</Table.Cell>
                  <Table.Cell textAlign="right">
                    ${raw.avg.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell textAlign="right">
                <strong>Grand Total</strong>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="right">
                <strong>
                  <Link
                    to={href({
                      startDate: filters.startDate,
                      endDate: filters.endDate,
                      byCreatedDate: filters.byCreatedDate,
                      sales: false,
                      agent: '',
                      campaign: '',
                      created: '',
                    })}
                  >
                    {summary.calls}
                  </Link>
                </strong>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="right">
                <strong>
                  <Link
                    to={href({
                      startDate: filters.startDate,
                      endDate: filters.endDate,
                      byCreatedDate: filters.byCreatedDate,
                      sales: true,
                      agent: '',
                      campaign: '',
                      created: '',
                    })}
                  >
                    {summary.sales}
                  </Link>
                </strong>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="right">
                <strong>
                  ${summary.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </strong>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="right">
                <strong>{(summary.conv * 100).toFixed(1)}%</strong>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="right">
                <strong>
                  ${summary.avg.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </Container>
  );
};

export default ReportingAgentAttribution;
