import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader, Table } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useGetAgentAttributionDetailDataQuery } from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import useSearchQuery from 'src/hooks/useSearchQuery';
import NotFound from 'src/pages/not-found';
import { Container, Header, Row } from 'src/styles';

const { REACT_APP_BETA_URL: betaURL } = process.env;

const ReportingAgentAttributionDetailColumns = [
  'caseid',
  'status',
  'statusgroup',
  'sourcename',
  'campaign_id',
  'call_date',
  'full_name',
  'phone_number',
  'wrap_up',
  'system',
  'p1_unconverted',
  'createddate',
  'scheduled_amount',
  'scheduled_dates',
  'total_payments',
  'sale',

  'phone',
  'document_created_at',
  'document_created_at_et',
  'dataset_id',
  'dataset_name',
  'list_id',
  'list_name',
  'file_id',
  'most_recent_call_date',
  'most_recent_call_date_et',
  'recent_sip_response',
  'recent_call_status',
  'carrier_name',
  'call_count',
  'voicemail_drop_count',
  'recent_bot_campaign_name',
  'recent_bot_disposition_title',
  'recent_bot_duration',
  'recent_bot_transfer_did',
  'recent_bot_transfer_duration',
  'recent_bot_transfer_start_seconds',
  'recent_bot_transcript_has_agent',
  'raw_call_count',
  'is_blacklisted_client',
  'is_blacklisted_federal',
  'transfer_to',
  'blacklist_client_list_id',
  'blacklist_federal_list_id',
  'recent_dialer_disposition',
  'recent_dialer_created_date',
  'recent_dialer_entry_date',
  'e_score',
  'gender',
  'recent_bot_contact_status',
  'recent_call_sys_status',
  'dnc_status',
  'master_lead_status',
  'leadscore',
  'recent_hung_up_source',
] as const;

type ReportingAgentAttributionDetailColumn = typeof ReportingAgentAttributionDetailColumns[number];

const ReportingAgentAttributionDetails = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();
  const { replace } = useHistory();
  const query = useSearchQuery();

  const [column, setColumn] = useState<ReportingAgentAttributionDetailColumn>(
    (query.get('column') ?? 'caseid') as ReportingAgentAttributionDetailColumn
  );
  const [direction, setDirection] = useState<'ascending' | 'descending'>(
    (query.get('direction') ?? 'ascending') as 'ascending' | 'descending'
  );

  const updateSort = useCallback(
    (col: ReportingAgentAttributionDetailColumn) => () => {
      const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setDirection(newDirection);
      setColumn(col);

      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [column, direction, query, replace]
  );

  const params = useMemo(
    () => ({
      startDate: query.get('startDate') ?? format(new Date(), 'yyyy-MM-dd'),
      endDate: query.get('endDate') ?? format(new Date(), 'yyyy-MM-dd'),
      byCreatedDate: query.get('byCreatedDate') === 'true',
      agent: query.get('agent'),
      campaign: query.get('campaign'),
      created: query.get('created'),
      sales: query.get('sales') === 'true',
    }),
    [query]
  );

  const DOWNLOAD_URL = useMemo(() => {
    const url = new URL(`${betaURL}/api/reporting/agent-attribution-detail/download`);

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      searchParams.append(key, value?.toString() ?? '');
    }

    url.search = searchParams.toString();

    return url.toString();
  }, [params]);

  const { data: AgentAttributionDetailData, isLoading: isDataLoading } = useGetAgentAttributionDetailDataQuery(params);

  const data = useMemo(() => {
    return AgentAttributionDetailData?.data || [];
  }, [AgentAttributionDetailData?.data]);

  const totalPayments = useMemo(() => data.reduce((sum, item) => sum + (item.total_payments ?? 0), 0), [data]);
  const totalSales = useMemo(() => data.reduce((sum, item) => sum + (item.sale ? 1 : 0), 0), [data]);

  const sortedData = useMemo(
    () =>
      data.sort((a, b) => {
        if (!column) return 1;

        const aValue = a[column];
        const bValue = b[column];

        if (aValue && bValue) {
          if (direction === 'ascending') {
            return aValue > bValue ? 1 : -1;
          }

          return aValue < bValue ? 1 : -1;
        }

        if (direction === 'ascending') {
          return a[column] ? 1 : -1;
        }

        return b[column] ? 1 : -1;
      }),
    [data, column, direction]
  );

  if (isLoading) return <Loading />;

  if (!user || !user.active_account) return <NotFound />;

  return (
    <Container>
      <Helmet>
        <title>Agent Attribution Detail - Reporting | datascore</title>
      </Helmet>

      <Header>
        <Row style={{ justifyContent: 'space-between' }}>
          <Breadcrumbs crumbs={['Reporting', 'Agent Attribution Detail']} />

          <Button as="a" href={DOWNLOAD_URL} basic style={{ marginBottom: '1rem' }}>
            <Icon name="download" /> Export CSV
          </Button>
        </Row>
      </Header>

      <div style={{ overflowX: 'auto' }}>
        <Table compact collapsing celled selectable sortable>
          <Table.Header>
            <Table.Row>
              {ReportingAgentAttributionDetailColumns.map(header => (
                <Table.HeaderCell
                  textAlign="right"
                  sorted={(column === header && direction) || undefined}
                  onClick={updateSort(header as ReportingAgentAttributionDetailColumn)}
                >
                  {header}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isDataLoading ? (
              <Table.Row style={{ position: 'relative' }}>
                <Table.Cell colSpan={55} textAlign="center">
                  <Loader active inline />
                </Table.Cell>
              </Table.Row>
            ) : (
              sortedData.map((row, index) => (
                <Table.Row key={index}>
                  {ReportingAgentAttributionDetailColumns.map(header => {
                    const value = row[header as ReportingAgentAttributionDetailColumn];
                    const formated =
                      header === 'sale' || header === 'is_blacklisted_client' || header === 'is_blacklisted_federal'
                        ? value
                          ? 'True'
                          : 'False'
                        : header === 'total_payments'
                        ? value
                          ? `$${value?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}`
                          : ''
                        : value;

                    return <Table.Cell textAlign="right">{formated}</Table.Cell>;
                  })}
                </Table.Row>
              ))
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={14} textAlign="right">
                <strong>Grand Total</strong>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="center">
                <strong>
                  ${totalPayments.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </strong>
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="center">
                <strong>{totalSales}</strong>
              </Table.HeaderCell>

              <Table.HeaderCell colSpan={39} textAlign="right"></Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </Container>
  );
};

export default ReportingAgentAttributionDetails;
