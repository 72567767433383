import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import { useRemoveQualifaiCampaignFromAccountMutation } from 'src/api/admin/accounts';

type Props = {
  accountId: number;
  campaignId: number;
};

const RemoveCampaignAccess = ({ accountId, campaignId }: Props) => {
  const { isLoading, mutateAsync } = useRemoveQualifaiCampaignFromAccountMutation();

  const onClick = useCallback(async () => {
    await mutateAsync({ accountId, campaignId });
  }, [accountId, campaignId, mutateAsync]);

  return <Button icon="trash" color="red" onClick={onClick} loading={isLoading} />;
};

export default RemoveCampaignAccess;
