import styled, { css } from 'styled-components';

export const Center = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

type ContainerProps = {
  readonly flex?: boolean;
};

export const Container = styled.div<ContainerProps>`
  padding: 2rem;
  ${p =>
    p.flex &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const SegmentHeader = styled.div`
  margin: -1rem -1rem 1rem -1rem;
  padding: 2rem;
  border-bottom: 1px solid ${p => p.theme.borderColor};
`;

export const Header = styled.h2`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const Subhead = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.1ch;
  margin-bottom: 0.25rem;
  color: ${p => p.theme.gray};
`;

type DividerProps = {
  readonly clear?: boolean;
};

export const HorizontalDivider = styled.div<DividerProps>`
  margin: 2rem 0;
  border-bottom: 1px solid ${p => (p.clear ? 'transparent' : p.theme.borderColor)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Main = styled.main`
  position: relative;
  padding-top: 50px;
  padding-left: 200px;
  color: ${p => p.theme.black};
  display: flex;
  flex-direction: column;

  body.role--admin & {
    padding-top: 84px;
  }

  body.nav--collapsed & {
    padding-left: 70px;
  }
`;

export const Note = styled.p`
  font-size: 0.9rem;
  margin-top: -0.5rem !important;
  color: ${p => p.theme.gray};

  label + & {
    margin-top: 0 !important;
  }

  blockquote {
    margin: 0.25rem 0 0;
    padding: 0.1rem 0 0.1rem 0.5rem;
    border-left: 2px solid #ddd;
  }
`;

export const StyledFieldset = styled.fieldset`
  padding: 0.5rem 1rem 1rem;
  margin: -0.5rem 0 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;

  legend {
    padding: 0 0.5rem;
    font-size: 0.9rem;
    color: #999;
  }

  .fields:last-child {
    margin-bottom: 0;
  }
`;
