import { Redirect, Route, Switch } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import AdminAccountsBillingConnectProHistory from './pages/admin/accounts/billing/connect-pro/history';
import AdminAccountsBillingLeadscorePlusHistory from './pages/admin/accounts/billing/leadscore-plus/history';
import AdminAccountsEdit from './pages/admin/accounts/edit';
import AdminAccountsList from './pages/admin/accounts/list';
import AdminBigqueryDatasetEdit from './pages/admin/bigquery/datasets/edit';
import AdminJobsList from './pages/admin/jobs/list';
import AdminLeadscorePlusAkkioModelsEdit from './pages/admin/leadscore-plus/akkio-models/edit';
import AdminLeadscorePlusAkkioModelsList from './pages/admin/leadscore-plus/akkio-models/list';
// import AdminBigqueryDatasetEdit from './pages/admin/bigquery/datasets/edit';
// import AdminBigqueryDatasetsList from './pages/admin/bigquery/datasets/list';
import AdminEditLeadscoreConfig from './pages/admin/leadscore-plus/configs/edit';
import AdminListLeadscoreConfigs from './pages/admin/leadscore-plus/configs/list';
import AdminModelsList from './pages/admin/models/list';
import AdminPipelinesEdit from './pages/admin/pipelines/edit';
import AdminPipelinesList from './pages/admin/pipelines/list';
import AdminQualifaiCampaignsEdit from './pages/admin/qualifai/campaigns/edit';
import AdminQualifaiCampaignsList from './pages/admin/qualifai/campaigns/list';
import AdminReportingBandwidthCallStats from './pages/admin/reporting/bandwidth-call-stats';
import AdminReportingTwilioCallStats from './pages/admin/reporting/twilio-call-stats';
import AdminReportingV1 from './pages/admin/reporting/v1';
import AdminReportingV2 from './pages/admin/reporting/v2';
import AdminReportingV2OverTime from './pages/admin/reporting/v2/over-time';
import AdminUsersEdit from './pages/admin/users/edit';
import AdminUsersList from './pages/admin/users/list';
import AdminWebhookConfigEdit from './pages/admin/webhooks/edit';
import AdminWebhookConfigsList from './pages/admin/webhooks/list';
import NotFound from './pages/not-found';
import AccountBandwidth from './pages/user/account/bandwidth';
import AccountsBillingDocs from './pages/user/account/billing/docs';
// import Tos from './pages/public/Tos';
import AccountsBillingSettings from './pages/user/account/billing/settings';
import AccountSendGrid from './pages/user/account/sendgrid';
import AccountSendGridInboundParse from './pages/user/account/sendgrid/inbound-parse';
import AccountSettings from './pages/user/account/settings';
import AccountTelnyx from './pages/user/account/telnyx';
import AccountTwilio from './pages/user/account/twilio';
import AccountsList from './pages/user/accounts/list';
import AuthUserSettings from './pages/user/auth/user/settings';
import BlacklistDocs from './pages/user/blacklist/docs';
import BlacklistsList from './pages/user/blacklist/list';
import ConvosoList from './pages/user/convoso/list';
import ConvosoViewFile from './pages/user/convoso/view-file';
import DataflowsHistory from './pages/user/dataflows/history';
import DataflowsList from './pages/user/dataflows/list';
import DataflowsView from './pages/user/dataflows/view';
import EditDataset from './pages/user/datasets/edit';
import DatasetsList from './pages/user/datasets/list';
import DocsDatasetV2 from './pages/user/datasets-v2/docs';
import EditDatasetV2 from './pages/user/datasets-v2/edit';
import ListDatasetsV2 from './pages/user/datasets-v2/list';
import FiltersList from './pages/user/filters/list';
import EditLeadscoreConfig from './pages/user/leadscore-plus/configs/edit';
import ListLeadscoreConfigs from './pages/user/leadscore-plus/configs/list';
import ModelsList from './pages/user/models/list';
import ModelsEngagementSchedule from './pages/user/models/schedule';
import Onboarding from './pages/user/onboarding/Onboarding';
import PipelinesEdit from './pages/user/pipelines/edit';
import PipelinesList from './pages/user/pipelines/list';
import ReportingAgentAttribution from './pages/user/reporting/agent-attribution';
import ReportingAgentAttributionDetails from './pages/user/reporting/agent-attribution/details';
import ReportingDashboard from './pages/user/reporting/dashboard';
import ReportingLeadSummary from './pages/user/reporting/lead-summary';
import ReportingStats from './pages/user/reporting/stats';
import VoiceConfigEdit from './pages/user/voice/configs/edit';
import VoiceConfigsList from './pages/user/voice/configs/list';
import VoiceConversationEdit from './pages/user/voice/conversations/edit';
import VoiceConversationsList from './pages/user/voice/conversations/list';
import VoiceCallRecordingsList from './pages/user/voice/recordings/list';
import WebhookConfigEdit from './pages/user/webhooks/edit';
import WebhookConfigsList from './pages/user/webhooks/list';
import Welcome from './pages/user/welcome';
import RestrictedRoute from './RestrictedRoute';
import { AccountPermission as AP } from './types';

const Routes = () => (
  <Switch>
    {/* <Route exact path="/tos" component={Tos} /> */}
    <Route exact path="/onboarding/:step" component={Onboarding} />
    <Redirect exact path="/onboarding" to="/onboarding/business-profile" />

    {/* Admin */}
    <AdminRoute
      exact
      path="/admin/accounts/:id/billing/leadscore-plus/history"
      component={AdminAccountsBillingLeadscorePlusHistory}
    />
    <AdminRoute
      exact
      path="/admin/accounts/:id/billing/connect-pro/history"
      component={AdminAccountsBillingConnectProHistory}
    />
    <AdminRoute exact path="/admin/accounts/:id" component={AdminAccountsEdit} />
    <AdminRoute exact path="/admin/accounts" component={AdminAccountsList} />
    <AdminRoute exact path="/admin/jobs" component={AdminJobsList} />
    <AdminRoute exact path="/admin/bigquery/datasets/:id" component={AdminBigqueryDatasetEdit} />
    <AdminRoute exact path="/admin/leadscore-plus/configs/:id" component={AdminEditLeadscoreConfig} />
    <AdminRoute exact path="/admin/leadscore-plus/configs" component={AdminListLeadscoreConfigs} />
    <AdminRoute exact path="/admin/leadscore-plus/akkio-models/:id" component={AdminLeadscorePlusAkkioModelsEdit} />
    <AdminRoute exact path="/admin/leadscore-plus/akkio-models" component={AdminLeadscorePlusAkkioModelsList} />
    <Redirect exact path="/admin/leadscore-plus" to="/admin/leadscore-plus/configs" />
    <AdminRoute exact path="/admin/models" component={AdminModelsList} />
    <AdminRoute exact path="/admin/pipelines/:id" component={AdminPipelinesEdit} />
    <AdminRoute exact path="/admin/pipelines" component={AdminPipelinesList} />
    <AdminRoute exact path="/admin/qualifai/campaigns/:id" component={AdminQualifaiCampaignsEdit} />
    <AdminRoute exact path="/admin/qualifai/campaigns" component={AdminQualifaiCampaignsList} />
    <Redirect exact path="/admin/qualifai" to="/admin/qualifai/campaigns" />
    <AdminRoute exact path="/admin/reporting/bandwidth-call-stats" component={AdminReportingBandwidthCallStats} />
    <AdminRoute exact path="/admin/reporting/twilio-call-stats" component={AdminReportingTwilioCallStats} />
    <AdminRoute exact path="/admin/reporting/v1" component={AdminReportingV1} />
    <AdminRoute exact path="/admin/reporting/v2" component={AdminReportingV2} />
    <AdminRoute exact path="/admin/reporting/v2/over-time" component={AdminReportingV2OverTime} />
    <AdminRoute exact path="/admin/users/:id" component={AdminUsersEdit} />
    <AdminRoute exact path="/admin/users" component={AdminUsersList} />
    <AdminRoute exact path="/admin/webhooks/configs/:id" component={AdminWebhookConfigEdit} />
    <AdminRoute exact path="/admin/webhooks/configs" component={AdminWebhookConfigsList} />
    <Redirect exact path="/admin/webhooks" to="/admin/webhooks/configs" />

    {/* User */}
    <Route exact path="/account/billing/docs" component={AccountsBillingDocs} />
    <Route exact path="/account/billing/settings" component={AccountsBillingSettings} />
    <Redirect exact path="/account/billing" to="/account/billing/settings" />
    <RestrictedRoute exact path="/account/bandwidth" component={AccountBandwidth} permission={AP.BANDWIDTH} />
    <RestrictedRoute
      exact
      path="/account/sendgrid/inbound-parse/:id"
      component={AccountSendGridInboundParse}
      permission={AP.SENDGRID}
    />
    <RestrictedRoute exact path="/account/sendgrid" component={AccountSendGrid} permission={AP.SENDGRID} />
    <Route exact path="/account/settings" component={AccountSettings} />
    <RestrictedRoute exact path="/account/telnyx" component={AccountTelnyx} permission={AP.TELNYX} />
    <RestrictedRoute exact path="/account/twilio" component={AccountTwilio} permission={AP.TWILIO} />
    <Route exact path="/accounts" component={AccountsList} />

    <Route exact path="/auth/user/settings" component={AuthUserSettings} />

    <RestrictedRoute exact path="/blacklists/:id/docs" component={BlacklistDocs} permission={AP.BLACKLISTS} />
    <RestrictedRoute exact path="/blacklists" component={BlacklistsList} permission={AP.BLACKLISTS} />

    <RestrictedRoute exact path="/convoso/files/:id" component={ConvosoViewFile} permission={AP.CONVOSO} />
    <RestrictedRoute exact path="/convoso" component={ConvosoList} permission={AP.CONVOSO} />

    <RestrictedRoute exact path="/dataflows/:id" component={DataflowsView} permission={AP.DATAFLOWS} />
    <RestrictedRoute exact path="/dataflows" component={DataflowsList} permission={AP.DATAFLOWS} />
    <RestrictedRoute exact path="/dataflows/:id/history" component={DataflowsHistory} permission={AP.DATAFLOWS} />

    <RestrictedRoute exact path="/datasets/v1/:id" component={EditDataset} permission={AP.DATASETS} />
    <RestrictedRoute exact path="/datasets/v1" component={DatasetsList} permission={AP.DATASETS} />
    <RestrictedRoute exact path="/datasets/v2/:id/docs" component={DocsDatasetV2} permission={AP.DATASETS_V2} />
    <RestrictedRoute exact path="/datasets/v2/:id" component={EditDatasetV2} permission={AP.DATASETS_V2} />
    <RestrictedRoute exact path="/datasets/v2" component={ListDatasetsV2} permission={AP.DATASETS_V2} />

    <RestrictedRoute exact path="/filters" component={FiltersList} permission={AP.FILTERS} />

    <RestrictedRoute
      exact
      path="/leadscore-plus/configs/:id"
      component={EditLeadscoreConfig}
      permission={AP.LEADSCORE_PLUS}
    />
    <RestrictedRoute
      exact
      path="/leadscore-plus/configs"
      component={ListLeadscoreConfigs}
      permission={AP.LEADSCORE_PLUS}
    />
    <Redirect exact path="/leadscore-plus" to="/leadscore-plus/configs" />

    <RestrictedRoute exact path="/models/:id/schedule" component={ModelsEngagementSchedule} permission={AP.MODELS} />
    <RestrictedRoute exact path="/models" component={ModelsList} permission={AP.MODELS} />

    <RestrictedRoute exact path="/pipelines/:id" component={PipelinesEdit} permission={AP.PIPELINES} />
    <RestrictedRoute exact path="/pipelines" component={PipelinesList} permission={AP.PIPELINES} />

    <RestrictedRoute exact path="/reporting/dashboard" component={ReportingDashboard} permission={AP.REPORTING} />
    <RestrictedRoute exact path="/reporting/lead-summary" component={ReportingLeadSummary} permission={AP.REPORTING} />
    <RestrictedRoute exact path="/reporting/stats" component={ReportingStats} permission={AP.REPORTING} />
    <RestrictedRoute
      exact
      path="/reporting/agent-attribution"
      component={ReportingAgentAttribution}
      permission={AP.REPORTING}
    />
    <RestrictedRoute
      exact
      path="/reporting/agent-attribution/details"
      component={ReportingAgentAttributionDetails}
      permission={AP.REPORTING}
    />
    <Redirect exact path="/reporting" to="/reporting/stats" />

    <RestrictedRoute exact path="/voice/configs/:id" component={VoiceConfigEdit} permission={AP.VOICE} />
    <RestrictedRoute exact path="/voice/configs" component={VoiceConfigsList} permission={AP.VOICE} />
    <RestrictedRoute exact path="/voice/conversations/:id" component={VoiceConversationEdit} permission={AP.VOICE} />
    <RestrictedRoute exact path="/voice/conversations" component={VoiceConversationsList} permission={AP.VOICE} />
    <RestrictedRoute exact path="/voice/recordings" component={VoiceCallRecordingsList} permission={AP.VOICE} />
    <Redirect exact path="/voice" to="/voice/configs" />

    <RestrictedRoute exact path="/webhooks/configs/:id" component={WebhookConfigEdit} permission={AP.WEBHOOKS} />
    <RestrictedRoute exact path="/webhooks" component={WebhookConfigsList} permission={AP.WEBHOOKS} />

    <Route exact path="/welcome" component={Welcome} />

    <Redirect exact path="/auth/login" to="/welcome" />
    <Redirect exact path="/" to="/welcome" />

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
