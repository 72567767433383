import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader, Table } from 'semantic-ui-react';

import {
  GetLeadSummaryFilters,
  OrderDirection,
  ReportingLeadSummaryColumn,
  ReportingLeadSummaryColumns,
  useGetLeadSummaryQuery,
} from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';

const betaUrl = process.env.REACT_APP_BETA_URL;

const getFiltersFromQuery = (query: URLSearchParams): GetLeadSummaryFilters => {
  // const search = query.get('search') || undefined;

  const column = query.get('column') as ReportingLeadSummaryColumn;

  let direction: OrderDirection = 'ascending';
  if (query.get('direction') === 'descending') {
    direction = 'descending';
  }

  return {
    order_col: column || 'phone',
    order_dir: direction,
    master_lead_status: query.get('master_lead_status') || undefined,
    dataset_ids: query.getAll('dataset_ids[]') || undefined,
    doc_date_range: query.get('doc_date_range') || undefined,
    call_date_range: query.get('call_date_range') || undefined,
  };
};

const ReportingLeadSummary = () => {
  const { replace } = useHistory();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<GetLeadSummaryFilters>(() => getFiltersFromQuery(query));
  const { data: leadSummary, isLoading } = useGetLeadSummaryQuery({
    limit: 1000,
    offset: 0,
    ...filters,
  });

  // useEffect(() => {
  //   if (!query.has('enabled')) {
  //     query.set('enabled', '1');
  //     replace({ search: query.toString() });
  //   }
  // });

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const updateSort = useCallback(
    (col: ReportingLeadSummaryColumn) => () => {
      const newDirection =
        filters.order_col === col ? (filters.order_dir === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      // setFilters(prev => ({ ...prev, order_col: col, order_dir: newDirection }));
      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [filters.order_col, filters.order_dir, query, replace]
  );

  return (
    <Container>
      <Helmet>
        <title>Lead Summary - Reporting | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Reporting', 'Lead Summary']} />
        </Header>
        <Button as="a" href={`${betaUrl}/api/reporting/lead-summary/download?${query.toString()}`}>
          <Icon name="download" />
          Download CSV
        </Button>
      </Row>

      <div style={{ position: 'relative', height: 'calc(100vh - 240px)', overflow: 'auto' }}>
        <Table compact collapsing celled selectable sortable>
          <Table.Header>
            <Table.Row>
              {ReportingLeadSummaryColumns.map(c => (
                <Table.HeaderCell
                  sorted={(filters.order_col === c && filters.order_dir) || undefined}
                  onClick={updateSort(c)}
                >
                  {c}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isLoading ? (
              <Table.Row style={{ position: 'relative' }}>
                <Table.Cell colSpan={10} textAlign="center">
                  <Loader active inline />
                </Table.Cell>
              </Table.Row>
            ) : (
              leadSummary?.data?.map(d => (
                <Table.Row key={`${d.phone}:${d.document_created_at}`}>
                  {ReportingLeadSummaryColumns.map(c => (
                    <Table.Cell key={c}>{d[c]}</Table.Cell>
                  ))}
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </div>
    </Container>
  );
};

export default ReportingLeadSummary;
