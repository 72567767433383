import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Form, Header, Input, Label } from 'semantic-ui-react';

import { useCreateJobMutation } from 'src/api/admin/jobs';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import SelectAccounts from 'src/components/SelectAccounts';
import { Note } from 'src/styles';
import { JobType } from 'src/types';

const AgentaiCallsRecrawlAccountForm = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [accountId, setAccountId] = useState(0);
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([null, null]);
  const { mutateAsync, isLoading } = useCreateJobMutation();

  const onChangeDates = useCallback((daterange: [Date | null, Date | null]) => {
    setDaterange(daterange);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    const validationErrors: Record<string, string> = {};
    if (!accountId) {
      validationErrors.accountId = 'Account is required';
    }
    if (!daterange[0]) {
      validationErrors.dateRange = 'Start date is required';
    }
    if (!daterange[1]) {
      validationErrors.dateRange = 'End date is required';
    }
    setErrors(validationErrors);

    if (!isEmpty(validationErrors)) {
      return;
    }

    // NOTE: we check for null values above, so we *should* be able to safely destructure here
    const [start, end] = daterange as [Date, Date];

    try {
      await mutateAsync({
        type: JobType.AgentaiCallsRecrawlAccount,
        args: {
          startDate: format(start, 'yyyy-MM-dd'),
          endDate: format(end, 'yyyy-MM-dd'),
          accountId,
        },
      });

      setDaterange([null, null]);
      setAccountId(0);
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [accountId, daterange, mutateAsync]);

  return (
    <Form onSubmit={onSubmit}>
      <Header>AgentAI Calls - Recrawl Account</Header>
      <Note>
        This job is designed to recrawl and replace data in BigQuery. Data in the <code>boom_calls</code> table between
        the selected start and end dates will be deleted and replaced with new data from the Boom API and CDR files
        stored in Cloud Storage.
      </Note>

      <ApiMessage data={apiMessage} />

      <Form.Group>
        <SelectAccounts
          clearable
          label="Account"
          value={accountId || ''}
          onChange={(_, { value }) => setAccountId(value as number)}
          error={errors.accountId}
        />

        <Form.Field style={{ minWidth: 240 }} error={!!errors.dateRange}>
          <label>Date Range</label>
          <DatePicker
            selectsRange
            startDate={daterange[0]}
            endDate={daterange[1]}
            customInput={<Input fluid icon="calendar" iconPosition="left" />}
            monthsShown={2}
            showPopperArrow={false}
            onChange={onChangeDates}
          />
          {errors.dateRange && (
            <Label prompt pointing color="red">
              {errors.dateRange}
            </Label>
          )}
        </Form.Field>
      </Form.Group>

      <Button color="blue" loading={isLoading}>
        Submit
      </Button>
    </Form>
  );
};

export default AgentaiCallsRecrawlAccountForm;
