import { useQuery } from 'react-query';

import { ReportingTimeRange } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type AdminGetAccountsReportingResponse = ApiResponse & {
  stats?: {
    connectPct: number;
    connects: number;
    dials: number;
    humans: number;
    inbounds: number;
    transfers: number;
    transfersPct: number;
    xferToCall: number;
    xferToCallPct: number;
    avgTransferTime: number;
  };
};

export type AdminGetAccountsReportingParams = {
  timeRange: ReportingTimeRange;
  startDate: string;
  endDate: string;
  enabled?: boolean | undefined;
  voiceConfigId?: number | undefined;
  timezone?: string | undefined;
  interval?: string | undefined;
};

export const useAdminGetAccountsReportingQuery = (id: number, params: AdminGetAccountsReportingParams) => {
  return useQuery(['admin/accounts', id, 'reporting', params], async () => {
    const res = await http.get<AdminGetAccountsReportingResponse>(`/api/admin/accounts/${id}/reporting`, {
      params,
    });
    return res.data;
  });
};
