import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PaginatedResponse, QualifaiCampaign } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type ListQualifaiCampaignsFilters = {
  active?: boolean;
  search?: string;
};

type ListQualifaiCampaignsParams = ListQualifaiCampaignsFilters & {
  limit: number;
  offset: number;
};

export type QualifaiCampaignsResponse = ApiResponse & {
  campaigns: PaginatedResponse<QualifaiCampaign>;
};

export type QualifaiCampaignResponse = ApiResponse & {
  campaign: QualifaiCampaign;
};

export const useListAllQualifaiCampaignsQuery = (params: ListQualifaiCampaignsParams) => {
  return useQuery(['admin/qualifai', 'campaigns', params], async () => {
    const res = await http.get<QualifaiCampaignsResponse>(`/api/admin/agentai/campaigns`, { params });
    return res.data.campaigns;
  });
};

export const useGetQualifaiCampaignQuery = (id: number) => {
  return useQuery(['admin/qualifai', 'campaigns', id], async () => {
    const res = await http.get<QualifaiCampaignResponse>(`/api/admin/agentai/campaigns/${id}`);
    return res.data.campaign;
  });
};

type UpdateQualifaiCampaignInput = {
  id: number;
  outbound_did: string | null;
  prospect_list_uuid: string | null;
};

export const useUpdateQualifaiCampaignMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateQualifaiCampaignInput) => {
      const res = await http.post<QualifaiCampaignResponse>(`/api/admin/agentai/campaigns/${id}`, input);
      return res.data.campaign;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['admin/qualifai', 'campaigns', id]);
      },
    }
  );
};

export const useRefetchQualifaiCampaignsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>('/api/admin/agentai/campaigns/refetch');
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/qualifai']);
      },
    }
  );
};

type GetRefetchQualifaiCampaignsJobsResponse = ApiResponse & {
  activeJob: boolean;
};

export const useGetRefetchQualifaiCampaignsJobsQuery = () => {
  return useQuery(
    ['admin/qualifai', 'campaigns/refetch'],
    async () => {
      const res = await http.get<GetRefetchQualifaiCampaignsJobsResponse>(`/api/admin/agentai/campaigns/refetch`);
      return res.data.activeJob;
    },
    {
      retry: false,
      refetchInterval: 5e3,
    }
  );
};
