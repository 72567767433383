import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PaginatedResponse, QualifaiConversation } from 'src/types';
import { ApiResponse, http } from './http-common';

export type ListQualifaiConversationsFilters = {
  enabled?: boolean;
};

type ListQualifaiConversationsParams = ListQualifaiConversationsFilters & {
  limit: number;
  offset: number;
};

export type QualifaiConversationsResponse = ApiResponse & {
  conversations: PaginatedResponse<QualifaiConversation>;
};

export type QualifaiConversationResponse = ApiResponse & {
  conversation: QualifaiConversation;
};

export const useListAllQualifaiConversationsQuery = (params: ListQualifaiConversationsParams) => {
  return useQuery(['qualifai', 'conversations', params], async () => {
    const res = await http.get<QualifaiConversationsResponse>(`/api/agentai/conversations`, {
      params,
    });
    return res.data.conversations;
  });
};

export type AddQualifaiConversationInput = {
  name: string;
};

export const useAddQualifaiConversationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddQualifaiConversationInput) => {
      const res = await http.post<QualifaiConversationResponse>(`/api/agentai/conversations`, input);
      return res.data.conversation;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['qualifai', 'conversations']);
      },
    }
  );
};

export const useGetQualifaiConversationQuery = (id: string) => {
  return useQuery(['qualifai', 'conversations', id], async () => {
    const res = await http.get<QualifaiConversationResponse>(`/api/agentai/conversations/${id}`);
    return res.data.conversation;
  });
};

export const useDeleteQualifaiConversationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/agentai/conversations/${id}`);
      return res.data;
    },
    {
      onSuccess: _ => {
        queryClient.invalidateQueries(['qualifai', 'conversations']);
      },
    }
  );
};

export const useUpdateQualifaiConversationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (conv: QualifaiConversation) => {
      const res = await http.post<QualifaiConversationResponse>(`/api/agentai/conversations/${conv.id}`, conv);
      return res.data.conversation;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['qualifai', 'conversations']);
      },
    }
  );
};
