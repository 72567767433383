import { useQuery } from 'react-query';

import { PaginatedResponse, QualifaiCampaign } from 'src/types';
import { ApiResponse, http } from './http-common';

export type QualifaiCampaignsResponse = ApiResponse & {
  campaigns: PaginatedResponse<QualifaiCampaign>;
};

export type ListQualifaiCampaignsParams = {
  limit: number;
  offset: number;
};

export const useListQualifaiCampaignsQuery = (params: ListQualifaiCampaignsParams) => {
  return useQuery(['qualifai', 'campaigns', params], async () => {
    const res = await http.get<QualifaiCampaignsResponse>('/api/agentai/campaigns', { params });
    return res.data.campaigns;
  });
};
