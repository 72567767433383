import { FC } from 'react';
import { Divider, Header, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

export const GridLayout = styled.div`
  display: grid;
  grid-template-areas: 'general v1 nodes';
  grid-template-columns: 1fr auto 2fr;
  gap: 1rem;
`;

export const Layout: FC = ({ children }) => {
  return (
    <GridLayout>
      {children}

      <Divider className="full" vertical style={{ gridArea: 'v1', position: 'relative' }} />
    </GridLayout>
  );
};

export const PlaceholderItem = ({ small }: { small?: boolean }) => (
  <Placeholder fluid>
    <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>

    {!small && (
      <>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </>
    )}
  </Placeholder>
);

export const PlaceholderLayout = () => {
  return (
    <Layout>
      <div style={{ gridArea: 'general' }}>
        <Header>General</Header>
        <PlaceholderItem />
      </div>

      <div style={{ gridArea: 'nodes' }}>
        <Header>Nodes</Header>
        <PlaceholderItem />
      </div>
    </Layout>
  );
};
