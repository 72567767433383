import { useState } from 'react';
import { Button, Form, InputOnChangeData } from 'semantic-ui-react';

import { useUpdateQualifaiCampaignMutation } from 'src/api/admin/qualifai-campaigns';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { QualifaiCampaign } from 'src/types';

type Props = {
  campaign: QualifaiCampaign;
};

const AdminQualifaiCampaignGeneral = (props: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [campaign, setCampaign] = useState(props.campaign);
  const { isLoading, mutateAsync } = useUpdateQualifaiCampaignMutation();

  const onChange = (_e: React.FormEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    setCampaign(prev => ({ ...prev, [name]: value }));
  };

  const onSubmit = async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync({
        id: campaign.id,
        outbound_did: campaign.outbound_did,
        prospect_list_uuid: campaign.prospect_list_uuid,
      });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Form.Input label="Name" name="name" value={campaign.name} readOnly disabled />
      <Form.Input label="Outbound DID" value={campaign.outbound_did} name="outbound_did" onChange={onChange} />
      <Form.Input
        label="Prospect List UUID"
        value={campaign.prospect_list_uuid}
        name="prospect_list_uuid"
        onChange={onChange}
      />

      <Button color="blue" loading={isLoading} content="Save" />
    </Form>
  );
};

export default AdminQualifaiCampaignGeneral;
