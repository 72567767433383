import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';

import { ReportingQualifaiDispoStat, useGetAccountReportingQualifaiQuery } from 'src/api/auth/account-reporting';
import { ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { AccountReportingQualifai } from 'src/types';
import { ReportingFilters } from '.';
import PieChartWithLegend from './PieChartWithLegend';
import Stat from './Stat';

type Props = {
  config: AccountReportingQualifai;
  filters: ReportingFilters;
};

const QualifaiStats = ({ config, filters }: Props) => {
  const [detailedDispos, setDetailedDispos] = useState(false);
  const { data, error, isLoading } = useGetAccountReportingQualifaiQuery({
    startDate: filters.startDate,
    endDate: filters.endDate,
    voiceConfigId: filters.voiceConfigId,
  });

  const errorMsg: ApiMessageData | undefined = error
    ? {
        status: Number((error as AxiosError).response?.status) || 500,
        message: (error as AxiosError).response?.data?.message || '',
        success: false,
      }
    : undefined;

  const agentaiCalls = data?.agentaiCalls || [];
  const totalHumans = agentaiCalls.reduce((total, { humans }) => total + humans, 0);
  const totalTransfers = agentaiCalls.reduce((total, { transfers }) => total + transfers, 0);
  const transferPct = (totalTransfers / totalHumans) * 100 || 0;

  const groupedDispos = useMemo(() => {
    if (!detailedDispos || !data?.agentaiDispos) return data?.agentaiDispos || [];

    const groupings = {
      Success: ['Transfer Successful', 'scheduled-appointment'],
      Contact: ['Transfer in progress', 'Transfer Completed', 'Qualified', 'Call back requested'],
      'Contact Recall': [
        'No answer - not contacted',
        'Dead air',
        'Silence',
        'Voicemail',
        'Hang Up',
        'Prospect Dropped',
        'Not available',
        'Transfer Started',
        'Abandoned',
        'No response',
        'Prospect drop pre qualification',
        'Prospect Dropped with Audio',
        'Silent Prospect',
      ],
      'DNC Contact': ['IVR removal', 'profanity', 'Robocall', 'Robokiller', 'Do not call'],
      Complete: [
        'Wrong number',
        'Not interested',
        'Language Barrier',
        'Not Qualified',
        'NQ already insured',
        'no-twice',
        'already enrolled - complete',
        'already enrolled - incomplete',
        'Busy Signal',
        'CANCELED',
        'Crash',
        'Not insured',
        'Not Qualified Age',
        'not-home-owner',
        'NotQualified',
        'NQ state',
        'Repeat Caller',
      ],
    };

    const grouped = Object.entries(groupings).reduce((acc, [newDispo, oldDispos]) => {
      const count = data.agentaiDispos
        .filter(stat => oldDispos.includes(stat.label))
        .reduce((sum, stat) => sum + stat.count, 0);
      if (count > 0) {
        acc.push({ label: newDispo, count });
      }
      return acc;
    }, [] as ReportingQualifaiDispoStat[]);

    return grouped;
  }, [detailedDispos, data?.agentaiDispos]);

  return (
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment textAlign="center">
            <Header>QualifAi</Header>
          </Segment>

          {errorMsg && <ApiMessage data={errorMsg} />}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row style={{ rowGap: '2rem' }}>
        {config.humans && <Stat label="Humans" value={totalHumans} loading={isLoading} />}
        {config.transfers && <Stat label="Xfer to CallCenter" value={totalTransfers} loading={isLoading} />}
        {config.transfers && config.transfers_pct && (
          <Stat label="Transfer %" value={`${transferPct.toFixed(2)}%`} loading={isLoading} />
        )}

        {config.dispositions && (
          <Grid.Column width={16}>
            <Segment style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
              <Header textAlign="center" style={{ margin: 0 }}>
                QualifAi Dispositions
              </Header>
              <PieChartWithLegend data={groupedDispos} loading={isLoading} />
              <Row style={{ justifyContent: 'flex-end' }}>
                <Form.Checkbox
                  label="Detailed Dispositions"
                  toggle
                  checked={detailedDispos}
                  onChange={() => {
                    setDetailedDispos(!detailedDispos);
                  }}
                />
              </Row>
            </Segment>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default QualifaiStats;
