import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { CheckboxProps, DropdownProps, Form, Input, Loader, Table } from 'semantic-ui-react';

import { useListDatasetsQuery } from 'src/api/datasets';
import { DashboardData, DashboardDataFilters, useGetDashboardDataQuery, useGetListDates } from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header as PageHeader } from 'src/styles';
import { Dataset } from 'src/types';

const statusColorMap: Record<string, string> = {
  Success: '#92D04F',
  'Not Called': 'black',
  'Contact Recall': 'black',
  Recall: 'black',
  Contact: 'red',
  Complete: 'red',
  'Sys Complete': 'red',
  'DNC Lit': 'red',
  'DNC Contact': 'red',
  'DNC Client': 'red',
};

const ReportingDashboardColumns = ['status', 'count'] as const;

type ReportingDashboardColumn = typeof ReportingDashboardColumns[number];

type DatasetStats = { dataset: Dataset; count: number };

type DashboarDataWithDetails = DashboardData & { details?: Record<string, DatasetStats> };

const DatePickerListDatesColumns: string[] = [
  'dataset_documents.created_at',
  'vw_de_lead_summary.most_recent_call_date',
];

const ReportingDashboard = () => {
  const [filters, setFilters] = useState<DashboardDataFilters>({
    dataset_ids: [],
    breakdown_by_dataset: false,
    doc_date_range: {},
    call_date_range: {},
  });
  const { data: datasets, isLoading: datasetLoading } = useListDatasetsQuery({ limit: 100, offset: 0 });
  const { replace } = useHistory();
  const query = useSearchQuery();
  const [column, setColumn] = useState<ReportingDashboardColumn>();
  const [datasetSelected, setDatasetselected] = useState<string>('');
  const [direction, setDirection] = useState<'ascending' | 'descending'>('ascending');

  const onChangeFilters = useCallback((_, { checked, name, value }: DropdownProps | CheckboxProps) => {
    setFilters(prev => {
      const v = typeof checked !== 'undefined' ? checked : value;
      return { ...prev, [name]: v };
    });
  }, []);

  const updateSort = useCallback(
    (col: ReportingDashboardColumn) => () => {
      const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setDirection(newDirection);
      setColumn(col);
      setDatasetselected('');
      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [column, direction, query, replace]
  );

  const updateDatasetSort = useCallback(
    (datasetId: string) => () => {
      const newDirection =
        datasetSelected === datasetId ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setColumn(undefined);
      setDirection(newDirection);
      setDatasetselected(datasetId);
      // update query params
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [datasetSelected, direction, query, replace]
  );

  const { data: dashboardData, isLoading } = useGetDashboardDataQuery(filters);

  const { data: listDates } = useGetListDates({ columns: DatePickerListDatesColumns });

  // Convert dataset_document dates to an array of Date objects
  const [availableDatesDoc, availableDatesCall] = useMemo(() => {
    if (!listDates) return [[], []];
    return [
      listDates['dataset_documents.created_at'].map(dateString => new Date(dateString + 'T00:00:00')),
      listDates['vw_de_lead_summary.most_recent_call_date'].map(dateString => new Date(dateString + 'T00:00:00')),
    ];
  }, [listDates]);

  const [data, allDatasets] = useMemo(() => {
    const rawData = dashboardData?.data || [];
    if (!filters.breakdown_by_dataset) return [rawData as DashboarDataWithDetails[], []];

    const uniqueStatus = new Map<string, Record<string, { dataset: Dataset; count: number }>>();
    const allDatasets = new Map<string, Dataset>();

    for (const raw of rawData) {
      if (!raw.dataset) continue;
      if (!uniqueStatus.has(raw.status)) {
        uniqueStatus.set(raw.status, {
          [raw.dataset.id]: {
            dataset: raw.dataset,
            count: raw.count,
          },
        });
      } else {
        const statusDatasets = uniqueStatus.get(raw.status);
        if (!statusDatasets) continue;
        if (statusDatasets[raw.dataset.id]) {
          statusDatasets[raw.dataset.id].count += raw.count;
        } else {
          statusDatasets[raw.dataset.id] = {
            dataset: raw.dataset,
            count: raw.count,
          };
        }
      }

      if (!allDatasets.has(raw.dataset.id)) allDatasets.set(raw.dataset.id, raw.dataset);
    }

    for (const [datasetId, completeDataset] of allDatasets.entries()) {
      for (const [status, datasetsAvailables] of uniqueStatus.entries()) {
        if (datasetsAvailables[datasetId]) continue;

        datasetsAvailables[datasetId] = {
          dataset: completeDataset,
          count: 0,
        };

        uniqueStatus.set(status, datasetsAvailables);
      }
    }

    const cleanData: DashboarDataWithDetails[] = [];

    for (const [status, datasets] of uniqueStatus.entries()) {
      let total = 0;

      for (const datasetId in datasets) {
        total += datasets[datasetId].count;
      }

      cleanData.push({
        count: total,
        dataset: null,
        details: datasets,
        status,
      });
    }

    return [cleanData, Array.from(allDatasets.values())];
  }, [dashboardData?.data, filters.breakdown_by_dataset]);

  const sortedData = useMemo(() => {
    const sortByGeneralStats = (a: DashboarDataWithDetails, b: DashboarDataWithDetails) => {
      if (!column) return 1;
      if (direction === 'ascending') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    };

    const sortByDatasetStats = (a: DashboarDataWithDetails, b: DashboarDataWithDetails) => {
      const aDataset = a.details?.[datasetSelected] ?? { count: 0 };
      const bDataset = b.details?.[datasetSelected] ?? { count: 0 };
      if (direction === 'ascending') {
        return aDataset.count > bDataset.count ? 1 : -1;
      } else {
        return aDataset.count < bDataset.count ? 1 : -1;
      }
    };

    return data.sort(datasetSelected !== '' ? sortByDatasetStats : sortByGeneralStats);
  }, [column, data, direction, datasetSelected]);

  const grandTotalMapped = useMemo(() => {
    const countMap: Record<string, number> = {};

    countMap['total'] = 0;
    for (const raw of data) {
      countMap['total'] += raw.count;

      if (!raw.details) continue;
      for (const datasetId of Object.keys(raw.details)) {
        const datasetsStats = raw.details[datasetId];
        if (!countMap[datasetsStats.dataset.name]) {
          countMap[datasetsStats.dataset.name] = datasetsStats.count;
        } else {
          countMap[datasetsStats.dataset.name] += datasetsStats.count;
        }
      }
    }

    return countMap;
  }, [data]);

  const handleDocDatesChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFilters(prevFilters => ({
      ...prevFilters,
      doc_date_range: { start: start !== null ? start : undefined, end: end !== null ? end : undefined },
    }));
  };

  const handleCallDatesChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFilters(prevFilters => ({
      ...prevFilters,
      call_date_range: { start: start !== null ? start : undefined, end: end !== null ? end : undefined },
    }));
  };

  const docDateStr = () => {
    return filters?.doc_date_range?.start && filters?.doc_date_range?.end
      ? `&doc_date_range=${JSON.stringify(filters.doc_date_range)}`
      : '';
  };

  const callDateStr = () => {
    return filters?.call_date_range?.start && filters?.call_date_range?.end
      ? `&call_date_range=${JSON.stringify(filters.call_date_range)}`
      : '';
  };

  return (
    <Container>
      <Helmet>
        <title>Dashboard - Reporting | datascore</title>
      </Helmet>

      <PageHeader>
        <Breadcrumbs crumbs={['Reporting', 'Dashboard']} />
      </PageHeader>

      <Form>
        <Form.Group>
          <Form.Select
            label="Dataset(s)"
            clearable
            multiple
            placeholder="All Datasets"
            name="dataset_ids"
            onChange={onChangeFilters}
            loading={datasetLoading}
            value={filters.dataset_ids || []}
            options={datasets?.data.map(ds => ({ key: ds.id, value: ds.id, text: ds.name })) || []}
          />
          <Form.Field>
            <label>Created Date</label>
            <DatePicker
              selected={filters?.doc_date_range?.start}
              onChange={handleDocDatesChange}
              startDate={filters?.doc_date_range?.start}
              endDate={filters?.doc_date_range?.end}
              selectsRange
              includeDates={availableDatesDoc}
              customInput={<Input icon="calendar" iconPosition="left" />}
            />
          </Form.Field>
          <Form.Field>
            <label>Recent Call Date</label>
            <DatePicker
              selected={filters?.call_date_range?.start}
              onChange={handleCallDatesChange}
              startDate={filters?.call_date_range?.start}
              endDate={filters?.call_date_range?.end}
              selectsRange
              includeDates={availableDatesCall}
              customInput={<Input icon="calendar" iconPosition="left" />}
            />
          </Form.Field>
          <Form.Checkbox
            toggle
            label="Breakdown by Dataset"
            checked={filters.breakdown_by_dataset || false}
            name="breakdown_by_dataset"
            onChange={onChangeFilters}
          />
        </Form.Group>
      </Form>

      <div style={{ overflowX: 'auto' }}>
        <Table compact collapsing celled selectable sortable>
          <Table.Header>
            {filters.breakdown_by_dataset && (
              <Table.Row>
                <Table.HeaderCell />
                {allDatasets.map(ds => (
                  <Table.HeaderCell
                    key={'header-' + ds.name}
                    onClick={updateDatasetSort(ds.id)}
                    textAlign="right"
                    colSpan="2"
                  >
                    {ds.name}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell onClick={updateSort('count')} textAlign="right" colSpan="2">
                  Total
                </Table.HeaderCell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.HeaderCell sorted={(column === 'status' && direction) || undefined} onClick={updateSort('status')}>
                Master Status
              </Table.HeaderCell>
              {allDatasets.map(ds => (
                <>
                  <Table.HeaderCell
                    onClick={updateDatasetSort(ds.id)}
                    sorted={(datasetSelected === ds.id && direction) || undefined}
                    textAlign="right"
                  >
                    Count
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">%</Table.HeaderCell>
                </>
              ))}
              <Table.HeaderCell
                textAlign="right"
                sorted={(column === 'count' && direction) || undefined}
                onClick={updateSort('count')}
              >
                Count
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">%</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isLoading ? (
              <Table.Row style={{ position: 'relative' }}>
                <Table.Cell colSpan={10} textAlign="center">
                  <Loader active inline />
                </Table.Cell>
              </Table.Row>
            ) : (
              sortedData.map(raw => (
                <Table.Row key={raw.status}>
                  <Table.Cell>
                    <Link
                      style={{
                        color: statusColorMap[raw.status],
                      }}
                      to={{
                        pathname: `/reporting/lead-summary`,
                        search: `master_lead_status=${raw.status}${filters.dataset_ids
                          ?.map(id => `&dataset_ids[]=${id}`)
                          .join('')}${docDateStr()}${callDateStr()}`,
                      }}
                    >
                      {raw.status}
                    </Link>
                  </Table.Cell>
                  {raw.details &&
                    allDatasets.map(ds => (
                      <>
                        <Table.HeaderCell textAlign="right">
                          <Link
                            to={{
                              pathname: `/reporting/lead-summary`,
                              search: `master_lead_status=${raw.status}${
                                ds.id ? `&dataset_ids[]=${ds.id}` : ``
                              }${docDateStr()}${callDateStr()}`,
                            }}
                          >
                            {raw.details?.[ds.id].count.toLocaleString()}
                          </Link>
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {(((raw.details?.[ds.id].count || 0) / grandTotalMapped[ds.name]) * 100).toFixed(2)}%
                        </Table.HeaderCell>
                      </>
                    ))}
                  <Table.Cell textAlign="right">
                    <Link
                      to={{
                        pathname: `/reporting/lead-summary`,
                        search: `master_lead_status=${raw.status}${filters.dataset_ids
                          ?.map(id => `&dataset_ids[]=${id}`)
                          .join('')}${docDateStr()}${callDateStr()}`,
                      }}
                    >
                      {raw.count.toLocaleString()}
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {((raw.count / grandTotalMapped['total']) * 100).toFixed(2)}%
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>
                <strong>Grand Total</strong>
              </Table.HeaderCell>
              {allDatasets.length > 0 &&
                allDatasets.map(ds => (
                  <>
                    <Table.HeaderCell textAlign="right">
                      <Link
                        to={{
                          pathname: `/reporting/lead-summary`,
                          search: `&dataset_ids[]=${ds.id}${docDateStr()}${callDateStr()}`,
                        }}
                      >
                        <strong>{grandTotalMapped[ds.name]?.toLocaleString()}</strong>
                      </Link>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      <strong>100%</strong>
                    </Table.HeaderCell>
                  </>
                ))}
              <Table.HeaderCell textAlign="right">
                <Link
                  to={{
                    pathname: `/reporting/lead-summary`,
                    search: `${filters.dataset_ids
                      ?.map(id => `&dataset_ids[]=${id}`)
                      .join('')}${docDateStr()}${callDateStr()}`,
                  }}
                >
                  <strong>{grandTotalMapped['total'].toLocaleString()}</strong>
                </Link>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                <strong>100.00%</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </Container>
  );
};

export default ReportingDashboard;
