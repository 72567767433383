import { useQuery } from 'react-query';

import { Dataset } from 'src/types';
import { ApiResponse, http } from './http-common';

export type DashboardData = {
  status: string;
  count: number;
  dataset: Dataset | null;
};

export type DashboardDataResponse = ApiResponse & {
  data?: DashboardData[];
};

export type DashboardDataFilters = {
  dataset_ids?: string[];
  breakdown_by_dataset?: boolean;
  doc_date_range?: { start?: Date; end?: Date }; // filter by document_created_at range date
  call_date_range?: { start?: Date; end?: Date }; // filter by most_recent_call_date range date
};

export const useGetDashboardDataQuery = (params: DashboardDataFilters) => {
  return useQuery(['reporting', 'dashboard', params], async () => {
    const res = await http.get<DashboardDataResponse>('/api/reporting/dashboard', {
      params,
    });
    return res.data;
  });
};

export type ListDatesParams = {
  columns?: string[];
};

export type ListDatesResponse = ApiResponse & {
  dates: {
    'dataset_documents.created_at': string[];
    'vw_de_lead_summary.most_recent_call_date': string[];
  };
};

export const useGetListDates = (params: ListDatesParams) => {
  return useQuery(['reporting', 'list-dates', params], async () => {
    const res = await http.get<ListDatesResponse>('/api/reporting/list-dates', {
      params,
    });
    return res.data.dates;
  });
};

export type LeadSummary = {
  phone: string;
  document_created_at: string;
  document_created_at_et: string;
  list_id: string;
  list_name: string;
  dataset_id: string;
  dataset_name: string;
  file_id: string;
  most_recent_call_date: string | null;
  most_recent_call_date_et: string | null;
  recent_sip_response: number;
  recent_call_status: string;
  carrier_name: string;
  call_count: number;
  voicemail_drop_count: number;
  recent_bot_disposition_title: string;
  recent_bot_duration: number;
  recent_bot_transfer_did: string;
  recent_bot_transfer_start_seconds: number;
  recent_bot_transfer_duration: number;
  recent_bot_transcript_has_agent: string | null;
  raw_call_count: number;
  transfer_to: string;
  recent_bot_campaign_name: string;
  is_blacklisted_client: boolean;
  is_blacklisted_federal: boolean;
  blacklist_client_list_id: string;
  blacklist_federal_list_id: string;
  recent_dialer_disposition: string;
  recent_dialer_created_date: string;
  recent_dialer_entry_date: string;
  e_score: number;
  gender: string;
  recent_bot_contact_status: string;
  recent_call_sys_status: string;
  dnc_status: string;
  master_lead_status: string;
  leadscore: string;
  recent_hung_up_source: string;
};

export const ReportingLeadSummaryColumns = [
  'phone',
  'document_created_at',
  'dataset_name',
  'list_name',
  'file_id',
  'master_lead_status',
  'most_recent_call_date',
  'recent_call_status',
  'recent_sip_response',
  'carrier_name',
  'call_count',
  'raw_call_count',
  'voicemail_drop_count',
  'recent_bot_campaign_name',
  'recent_bot_disposition_title',
  'recent_bot_duration',
  'recent_bot_transfer_did',
  'recent_bot_transfer_start_seconds',
  'recent_bot_transfer_duration',
  'recent_bot_transcript_has_agent',
  'transfer_to',
  'is_blacklisted_client',
  'blacklist_client_list_id',
  'is_blacklisted_federal',
  'blacklist_federal_list_id',
  'recent_dialer_disposition',
  'recent_dialer_created_date',
  'recent_dialer_entry_date',
  'e_score',
  'gender',
  'recent_bot_contact_status',
  'recent_call_sys_status',
  'dnc_status',
  'leadscore',
  'recent_hung_up_source',
] as const;

export type ReportingLeadSummaryColumn = typeof ReportingLeadSummaryColumns[number];

export type GetLeadSummaryFilters = {
  order_col?: ReportingLeadSummaryColumn;
  order_dir?: OrderDirection;
  master_lead_status?: string;
  recent_call_status?: string;
  dataset_ids?: string[];
  list_id?: string;
  doc_date_range?: string;
  call_date_range?: string;
};

export type OrderDirection = 'ascending' | 'descending';

export type GetLeadSummaryParams = GetLeadSummaryFilters & {
  limit: number;
  offset: number;
};

export type LeadSummaryResponse = ApiResponse & {
  data?: LeadSummary[];
};

export const useGetLeadSummaryQuery = (params: GetLeadSummaryParams) => {
  return useQuery(['reporting', 'lead-summary', params], async () => {
    const res = await http.get<LeadSummaryResponse>('/api/reporting/lead-summary', { params });
    return res.data;
  });
};

export type AgentAttributionDataFilters = {
  view: 'agent' | 'campaign' | 'created' | 'called';
  byCreatedDate: boolean;
  startDate: string;
  endDate: string;
  campaign?: string;
};

export type AgentAttributionData = {
  label: string;
  calls: number;
  sales: number;
  total: number;
  conv: number;
  avg: number;
};

export type AgentAttributionDataResponse = ApiResponse & {
  data?: AgentAttributionData[];
};

export type AgentAttributionDataDetailFilters = {
  startDate: string;
  endDate: string;
  byCreatedDate: boolean;
  agent: string | null;
  campaign: string | null;
  created: string | null;
  sales: boolean;
};

export type AgentAttributionDetailData = {
  caseid: string | null;
  status: string | null;
  statusgroup: string | null;
  sourcename: string | null;
  campaign_id: string | null;
  call_date: string | null;
  full_name: string | null;
  phone_number: string | null;
  wrap_up: string | null;
  system: string | null;
  p1_unconverted: number | null;
  createddate: string | null;
  total_payments?: number | null;
  sale?: boolean | null;
  scheduled_amount: number | null;
  scheduled_dates: string | null;

  phone: string | null;
  document_created_at: string | null;
  document_created_at_et: string | null;
  dataset_id: string | null;
  dataset_name: string | null;
  list_id: string | null;
  list_name: string | null;
  file_id: string | null;
  most_recent_call_date: string | null;
  most_recent_call_date_et: string | null;
  recent_sip_response: number | null;
  recent_call_status: string | null;
  carrier_name: string | null;
  call_count: number | null;
  voicemail_drop_count: number | null;
  recent_bot_campaign_name: string | null;
  recent_bot_disposition_title: string | null;
  recent_bot_duration: number | null;
  recent_bot_transfer_did: string | null;
  recent_bot_transfer_duration: number | null;
  recent_bot_transfer_start_seconds: number | null;
  recent_bot_transcript_has_agent: string | null;
  raw_call_count: number | null;
  is_blacklisted_client: boolean | null;
  is_blacklisted_federal: boolean | null;
  transfer_to: string | null;
  blacklist_client_list_id: number | null;
  blacklist_federal_list_id: number | null;
  recent_dialer_disposition: string | null;
  recent_dialer_created_date: string | null;
  recent_dialer_entry_date: string | null;
  e_score: number | null;
  gender: string | null;
  recent_bot_contact_status: string | null;
  recent_call_sys_status: string | null;
  dnc_status: string | null;
  master_lead_status: string | null;
  leadscore: string | null;
  recent_hung_up_source: string | null;
};

export type AgentAttributionDataDetailResponse = ApiResponse & {
  data?: AgentAttributionDetailData[];
};

export const useGetAgentAttributionDataQuery = (params: AgentAttributionDataFilters) => {
  return useQuery(['reporting', 'agent-attribution', params], async () => {
    const res = await http.get<AgentAttributionDataResponse>('/api/reporting/agent-attribution', {
      params,
    });
    return res.data;
  });
};

export const useGetAgentAttributionDetailDataQuery = (params: AgentAttributionDataDetailFilters) => {
  return useQuery(['reporting', 'agent-attribution-detail', params], async () => {
    const res = await http.get<AgentAttributionDataDetailResponse>('/api/reporting/agent-attribution-detail', {
      params,
    });
    return res.data;
  });
};
