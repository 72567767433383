import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Divider, Form, Header, Segment } from 'semantic-ui-react';

import Breadcrumbs from 'src/components/Breadcrumbs';
import JobHistory from 'src/components/JobHistory';
import { Container, Header as PageHeader, Row } from 'src/styles';
import { JobType } from 'src/types';
import AgentaiCallsRecrawlAccountForm from './AgentaiCallsRecrawlAccountForm';
import AgentaiCallsRecrawlAccountJobDetailsModal from './AgentaiCallsRecrawlAccountJobDetailsModal';

const renderJobForm = (jobType: JobType | 0) => {
  switch (jobType) {
    case JobType.AgentaiCallsRecrawlAccount:
      return <AgentaiCallsRecrawlAccountForm />;

    default:
      return null;
  }
};

const AdminJobsList = () => {
  const [jobType, setJobType] = useState<JobType | 0>(0);

  return (
    <Container>
      <Helmet>
        <title>Jobs - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <PageHeader style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Jobs']} />
        </PageHeader>
      </Row>

      <Segment>
        <Header>Run Job</Header>

        <Form>
          <Form.Group>
            <Form.Select
              clearable
              label="Job Name"
              placeholder="Select a job to run"
              value={jobType || ''}
              onChange={(_, { value }) => setJobType(value as JobType)}
              options={[
                {
                  key: JobType.AgentaiCallsRecrawlAccount,
                  text: 'AgentAI Calls - Recrawl Account',
                  value: JobType.AgentaiCallsRecrawlAccount,
                },
              ]}
            />
          </Form.Group>
        </Form>

        {jobType !== 0 && (
          <>
            <Divider />

            {renderJobForm(jobType)}

            <Divider />

            <JobHistory adminJobs jobType={jobType} details={AgentaiCallsRecrawlAccountJobDetailsModal} />
          </>
        )}
      </Segment>
    </Container>
  );
};

export default AdminJobsList;
