import { format, formatDistanceToNow } from 'date-fns';
import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { useGetAccountQuery } from 'src/api/admin/accounts';
import JobStatusLabel from 'src/components/JobHistory/JobStatusLabel';
import { AgentaiCallsRecrawlAccountJob, JobStatus } from 'src/types';

const AgentaiCallsRecrawlAccountJobDetailsModal = ({
  id: jobId,
  status,
  queued,
  payload,
}: AgentaiCallsRecrawlAccountJob) => {
  const { data: account } = useGetAccountQuery(payload.accountId);

  return (
    <Modal
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Header>Job Details</Modal.Header>
      <Modal.Content>
        {status === JobStatus.Error && typeof payload.error !== 'undefined' && payload.error.length > 0 && (
          <Label color="red">
            Error
            <Label.Detail>{payload.error}</Label.Detail>
          </Label>
        )}

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Job ID</Table.Cell>
              <Table.Cell>{jobId}</Table.Cell>
            </Table.Row>

            {queued && (
              <Table.Row>
                <Table.Cell>Created At</Table.Cell>
                <Table.Cell>
                  <span>{formatDistanceToNow(new Date(queued), { addSuffix: true, includeSeconds: true })}</span>{' '}
                  <Label>{format(new Date(queued), 'iii MMM do yyyy @ h:mm:ss a z')}</Label>
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>
                <JobStatusLabel status={status} />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Account</Table.Cell>
              <Table.Cell>
                {account && (
                  <>
                    <span>{account.name}</span>{' '}
                  </>
                )}

                <Label>
                  {payload.accountId}
                  <Label.Detail>Account ID</Label.Detail>
                </Label>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Date Range</Table.Cell>
              <Table.Cell>
                <Label>
                  {payload.startDate}
                  <Label.Detail>Start</Label.Detail>
                </Label>{' '}
                to{' '}
                <Label>
                  {payload.endDate}
                  <Label.Detail>End</Label.Detail>
                </Label>
              </Table.Cell>
            </Table.Row>

            {payload.campaignSlugs && (
              <Table.Row>
                <Table.Cell>Found AgentAI Campaigns</Table.Cell>
                <Table.Cell>
                  {payload.campaignSlugs.map(slug => (
                    <Label>{slug}</Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            )}

            {payload.processedCallsByCampaign && (
              <Table.Row>
                <Table.Cell>Processed Call Count(s)</Table.Cell>
                <Table.Cell>
                  {Object.entries(payload.processedCallsByCampaign).map(([campaignName, callCount]) => (
                    <Label>
                      {callCount}
                      <Label.Detail>{campaignName}</Label.Detail>
                    </Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default AgentaiCallsRecrawlAccountJobDetailsModal;
