import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Form, Grid, GridColumn } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import { ReportingTimeRange, ReportingTimeRanges } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';
import CarrierStats from './CarrierStats';
import DatascoreStats from './DatascoreStats';
import HourlyCallStats from './HourlyCallStats';
import QualifaiStats from './QualifaiStats';

export type ReportingFilters = {
  timeRange: ReportingTimeRange;
  startDate: string;
  endDate: string;
  voiceConfigId: string;
};

const ReportingStats = () => {
  const [filters, setFilters] = useState<ReportingFilters>({
    timeRange: 'today',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    voiceConfigId: '',
  });
  const { data: user, isLoading } = useGetUserProfileQuery();

  const { data: voiceConfigs, isLoading: isLoadingVoiceConfigs } = useListVoiceConfigsQuery({ limit: 100, offset: 0 });

  const onChangeTimeRange = useCallback((_, { value }) => {
    const [startDate, endDate] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);
    setFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange, startDate, endDate }));
  }, []);

  const onChangeVoiceConfigFilter = useCallback((_, { value }) => {
    setFilters(prev => ({ ...prev, voiceConfigId: value }));
  }, []);

  if (isLoading) return <Loading />;

  if (!user || !user.active_account) return <NotFound />;

  const a = user.active_account;

  return (
    <Container>
      <Helmet>
        <title>Stats - Reporting | datascore</title>
      </Helmet>

      <PageHeader>
        <Breadcrumbs crumbs={['Reporting', 'Stats']} />
      </PageHeader>

      <Form>
        <Form.Group>
          <Form.Field style={{ marginBottom: 0 }}>
            <label style={{ width: 230 }}>
              Time Range{' '}
              <span style={{ opacity: 0.4, fontSize: '0.9em', whiteSpace: 'nowrap' }}>
                ({filters.startDate} - {filters.endDate})
              </span>
            </label>

            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.filter(tr => tr !== 'custom').map(timeRange => ({
                key: timeRange,
                text: timeRange === 'this year' ? 'YTD' : capitalize(timeRange),
                value: timeRange,
              }))}
              value={filters.timeRange}
            />
          </Form.Field>

          <Form.Select
            label="Voice Config"
            clearable
            placeholder="No filter"
            onChange={onChangeVoiceConfigFilter}
            loading={isLoadingVoiceConfigs}
            options={
              voiceConfigs?.data.map(config => {
                return { key: config.id, value: config.id, text: config.name };
              }) || []
            }
          />
        </Form.Group>
      </Form>

      <Grid columns={6}>
        <Grid.Row>
          {a.reporting.datascore.enabled && (
            <GridColumn width={8}>
              <DatascoreStats config={a.reporting.datascore} filters={filters} />
            </GridColumn>
          )}

          {a.reporting.agentai.enabled && (
            <GridColumn width={8}>
              <QualifaiStats config={a.reporting.agentai} filters={filters} />
            </GridColumn>
          )}
        </Grid.Row>

        <Grid.Row>
          {(a.reporting.datascore.enabled || a.reporting.agentai.enabled) && (
            <GridColumn width={16}>
              <HourlyCallStats config={a.reporting} filters={filters} />
            </GridColumn>
          )}
        </Grid.Row>

        <Grid.Row>
          {a.reporting.carriers.enabled && (
            <Grid.Column width={16}>
              <CarrierStats filters={filters} />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default ReportingStats;
